export enum EnumRecipientStatusID {
  DEFAULT = 1,
  SENT_TO_CLIENT = 2,
  SAMPLE_VALIDATED = 3,
  SENT_TO_LAB = 4,
  UNDER_ANALYSIS = 5,
  ANALYSIS_COMPLETED = 6,
}

export enum EnumRecipientStatus {
  DEFAULT = "DEFAULT",
  SENT_TO_CLIENT = "SENT_TO_CLIENT",
  SAMPLE_VALIDATED = "SAMPLE_VALIDATED",
  SENT_TO_LAB = "SENT_TO_LAB",
  UNDER_ANALYSIS = "UNDER_ANALYSIS",
  ANALYSIS_COMPLETED = "ANALYSIS_COMPLETED",
}
