import { yupResolver } from "@hookform/resolvers/yup";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Sheet from "@mui/joy/Sheet";
import Typography from "@mui/joy/Typography";
import { useEffect } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { handleLogin } from "../../store/reducers/authSlice";
import { RootState } from "../../store/store";
import api from "../../utils/api/api";

const LoginPage = () => {
  const authStore = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formInputNames = {
    email: "email",
    password: "password",
  };

  const accessSchema = yup.object().shape({
    [formInputNames.email]: yup
      .string()
      .email("Email inválido")
      .required("Campo obrigatório"),
    [formInputNames.password]: yup
      .string()
      .min(8, "Senha deve ter no mínimo 6 caracteres")
      .required("Campo obrigatório"),
  });

  const { register, handleSubmit } = useForm({
    resolver: yupResolver(accessSchema),
  });

  const onSubmit = async (values: FieldValues) => {
    try {
      const response = await api.post(`/backoffice/access`, {
        email: values.email,
        password: values.password,
      });

      const data = response.data;

      localStorage.setItem("loginResponse", JSON.stringify(data));

      if (data?.accessToken) {
        dispatch(
          handleLogin({
            token: data.accessToken,
            user: data.user,
          })
        );
        navigate("/dashboard");
      }
    } catch (err: any) {
      console.log(err);
      if (err.response && err.response.data.message)
        toast.error("Erro ao fazer login: " + err.response.data.message);
    }
  };

  useEffect(() => {
    if (authStore.isAuthenticated) {
      console.log("isAuthenticated", authStore.isAuthenticated);
      navigate("/dashboard");
    }
  }, [authStore.isAuthenticated, navigate]);

  return (
    <div className="flex items-center justify-center h-screen">
      <Sheet
        variant="outlined"
        sx={{
          width: 300,
          mx: "auto",
          py: 3,
          px: 2,
          display: "flex",
          flexDirection: "column",
          gap: 2,
          borderRadius: "sm",
          boxShadow: "md",
        }}
      >
        <div>
          <Typography level="h4" component="h1">
            Bem-vindo!
          </Typography>
          <Typography level="body-sm">Entre para continuar.</Typography>
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col w-full "
        >
          <FormControl>
            <FormLabel>Email</FormLabel>
            <Input
              variant="soft"
              type="email"
              placeholder="email@reload.com"
              {...register(formInputNames.email)}
            />
          </FormControl>
          <FormControl
            sx={{
              mt: 2,
            }}
          >
            <FormLabel>Password</FormLabel>
            <Input
              variant="soft"
              type="password"
              placeholder="password"
              {...register(formInputNames.password)}
            />
          </FormControl>
          <Button
            type="submit"
            sx={{ mt: 2, ml: "auto", mr: "auto", width: "100%" }}
            size="lg"
          >
            Log in
          </Button>
        </form>
        {/* <Typography
          endDecorator={<Link href="/sign-up">Sign up</Link>}
          fontSize="sm"
          sx={{ alignSelf: "center" }}
        >
          Don't have an account?
        </Typography> */}
      </Sheet>
    </div>
  );
};

export default LoginPage;
