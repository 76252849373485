import { FormControl, Input } from "@mui/joy";
import { FieldErrors, FieldValues, UseFormRegister } from "react-hook-form";
import { Metabolite } from "../../../../../../@types";

interface InsertResultMetaboliteRowProps {
  htmlId: string;
  register: UseFormRegister<FieldValues>;
  errors: FieldErrors<FieldValues>;
  metabolite: Metabolite;
}

const InsertResultMetaboliteRow = ({
  htmlId,
  register,
  errors,
  metabolite,
}: InsertResultMetaboliteRowProps) => {
  return (
    <FormControl
      sx={{
        // border: "2px solid red",
        backgroundColor: "white",
        py: 0.5,
        px: 1,
        borderRadius: 4,
      }}
    >
      <div className="flex items-center justify-between">
        <span>{metabolite.name}</span>
        <div className="flex items-center justify-between gap-4 ">
          <span className="flex items-center justify-end w-24 font-medium text-gray-600">
            <span className="mr-2 text-xs font-light text-gray-400">MIN:</span>{" "}
            {metabolite.referenceRangeMin}
          </span>
          <div className="flex items-center ">
            {/* <FormLabel>Value</FormLabel> */}
            <span className="mr-2 text-xs font-light text-gray-400">
              RESULT:
            </span>
            <Input
              size="sm"
              variant="soft"
              {...register(htmlId)}
              type="number"
              inputMode="decimal"
              slotProps={{
                input: {
                  step: "any",
                },
              }}
              sx={{
                width: "80px",
              }}
            />
          </div>
          <span className="flex items-center w-24 font-medium text-gray-600">
            <span className="mr-2 text-xs font-light text-gray-400">MAX:</span>{" "}
            {metabolite.referenceRangeMax}
          </span>
        </div>
      </div>
      {errors[htmlId] && (
        <span className="mr-2 text-xs font-light text-center text-red-500">
          {errors[htmlId]?.message as string}
        </span>
      )}
    </FormControl>
  );
};

export default InsertResultMetaboliteRow;
