import { Refresh } from "@mui/icons-material";
import {
  Typography,
  Button,
  Box,
  LinearProgress,
  Select,
  Option,
  Autocomplete,
} from "@mui/joy";
import React from "react";
import Header from "../../../../components/layout/Header";
import { useNutrientStatsByRecipient } from "../../../../store/contexts/nutrientStatsByRecipient.context";
import { NutrientOption } from "../../../../@types";
import { StatsTable } from "./components/StatsTable";

export const NutrientStatsByRecipient: React.FC = () => {
  const {
    loadingNutrients,
    loadingStats,
    nutrients,
    stats,
    selectedNutrient,
    setSelectedNutrient,
    scoreSubCategories,
    setSelectedScoreSubCategory,
    handleFetchStatsData,
  } = useNutrientStatsByRecipient();

  const handleChangeNutrient = (
    event: React.SyntheticEvent | null,
    newValue: NutrientOption | null
  ) => {
    if (newValue) setSelectedNutrient(newValue);
  };

  return (
    <>
      <Header>
        <Typography level="body-lg" fontSize="xl">
          Nutrient Stats By Recipient
        </Typography>
        <Button
          color="primary"
          startDecorator={<Refresh />}
          variant="outlined"
          sx={{ ml: "auto" }}
          onClick={() => {
            if (selectedNutrient?.id) handleFetchStatsData(selectedNutrient.id);
          }}
        >
          Refresh
        </Button>
      </Header>

      <div className="p-4 rounded">
        <div className="mb-4">
          <div className="flex gap-2">
            <div className="flex w-96">
              <Select
                variant="soft"
                placeholder="Select Nutrient"
                sx={{ width: "100%" }}
                onChange={handleChangeNutrient}
                value={selectedNutrient}
                disabled={loadingNutrients}
              >
                {nutrients?.map((nutrient) => (
                  <Option key={nutrient.id} value={nutrient}>
                    {nutrient.name}
                  </Option>
                ))}
              </Select>
            </div>

            <div className="flex w-96">
              <Autocomplete
                sx={{
                  width: "100%",
                }}
                placeholder="Filter by subcategory score"
                options={scoreSubCategories}
                getOptionLabel={(option) => option.name}
                onChange={(event, value) => {
                  setSelectedScoreSubCategory(value?.id ?? null);
                }}
                groupBy={(option) => option.category}
                renderGroup={(params) => (
                  <div>
                    <strong className="ml-1 text-lg font-semibold">
                      {params.group}
                    </strong>
                    {params.children}
                  </div>
                )}
              />
            </div>
          </div>
        </div>
        <Box sx={{ width: "100%" }}>
          {loadingStats ? (
            <div className="">
              <LinearProgress />
            </div>
          ) : (
            <StatsTable stats={stats} />
          )}
        </Box>
      </div>
    </>
  );
};
