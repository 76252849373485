import { Sheet } from "@mui/joy";

interface HeaderProps {
  children?: React.ReactNode;
}

const Header = ({ children }: HeaderProps) => {
  return (
    <header className="w-full ">
      <Sheet
        variant="plain"
        sx={{
          height: 64,
          display: "flex",
          alignItems: "center",
          px: 2,
          width: "100%",
          borderBottom: "1px solid #E5E7EB",
        }}
      >
        {children}
      </Sheet>
    </header>
  );
};

export default Header;
