import { Assessment } from "@mui/icons-material";
import BlurOnIcon from "@mui/icons-material/BlurOn";
import Kitchen from "@mui/icons-material/Kitchen";
import InsightsIcon from "@mui/icons-material/Insights";
import LogoutIcon from "@mui/icons-material/Logout";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemDecorator,
  Sheet,
} from "@mui/joy";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import ReloadIcon from "../../assets/reload-icon.png";
import ReloadLogo from "../../assets/reload-logo.png";
import { handleLogout } from "../../store/reducers/authSlice";
import { RootState } from "../../store/store";

const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const auth = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();

  const [isExpanded, setIsExpanded] = useState(false);

  const handleClickLogout = () => {
    localStorage.removeItem("loginResponse");
    dispatch(handleLogout());
    navigate("/");
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleNavigate = (path: string) => {
    navigate(path);
  };
  const sidebarRoutes = [
    {
      name: "Metabolites",
      icon: <BlurOnIcon />,
      path: "/dashboard/metabolites",
      labAuth: false,
      adminAuth: true,
    },
    {
      name: "Recipients",
      icon: <Kitchen />,
      path: "/dashboard/recipients",
      labAuth: false,
      adminAuth: true,
    },
    {
      name: "Results",
      icon: <Assessment />,
      path: "/dashboard/results",
      labAuth: true,
      adminAuth: true,
    },
    {
      name: "Nutrient Stats By Recipient",
      icon: <InsightsIcon />,
      path: "/dashboard/nutrient-stats-by-recipient",
      labAuth: true,
      adminAuth: true,
    },
    {
      name: "Scores By Recipient",
      icon: <InsightsIcon />,
      path: "/dashboard/scores-by-recipient",
      labAuth: true,
      adminAuth: true,
    },
  ];

  return (
    <>
      <Box
        sx={{
          width: isExpanded ? "260px" : "80px",
          minWidth: isExpanded ? "260px" : "80px",
          height: "100vh",
          p: 2,
          position: "sticky",
          display: "flex",
          flexDirection: "column",
          transition: "width 0.3s",
        }}
      ></Box>
      <Sheet
        variant="outlined"
        sx={{
          width: isExpanded ? "260px" : "80px",
          minWidth: isExpanded ? "260px" : "80px",
          height: "100vh",
          p: 2,
          position: "fixed",
          display: "flex",
          flexDirection: "column",
          transition: "width 0.3s",
        }}
      >
        {isExpanded ? (
          <img
            src={ReloadLogo}
            alt="reload logo"
            className={twMerge(
              "w-48 mx-auto mt-1 mb-2 cursor-pointer transition-all opacity-0",
              isExpanded && "opacity-100"
            )}
            onClick={toggleExpand}
          />
        ) : (
          <img
            src={ReloadIcon}
            alt="reload logo"
            className={twMerge(
              " mt-1 h-10 w-10 cursor-pointer transition-all duration-300 ease-in-out opacity-0",
              !isExpanded && "opacity-100"
            )}
            onClick={toggleExpand}
          />
        )}
        <List
          sx={{
            mt: 4,
            gap: 1,
          }}
        >
          {sidebarRoutes.map((route) => {
            if (!auth.user?.hasAdminAuth) {
              if (!route.labAuth) {
                return null;
              }
            }

            return (
              <ListItem key={route.name}>
                <ListItemButton
                  selected={location.pathname === route.path}
                  onClick={() => handleNavigate(route.path)}
                >
                  <ListItemDecorator>{route.icon}</ListItemDecorator>
                  {isExpanded && route.name}
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
        <ListItem>
          <ListItemButton onClick={handleClickLogout}>
            <ListItemDecorator>
              <LogoutIcon />
            </ListItemDecorator>
          </ListItemButton>
        </ListItem>
      </Sheet>
    </>
  );
};

export default Sidebar;
