import { Autocomplete, Box, Button, CircularProgress, Input, Typography } from "@mui/joy";
import { useCallback, useEffect, useState } from "react";
import { Metabolite, ScoreSubcategory } from "../../../../@types";
import Header from "../../../../components/layout/Header";
import { useMetaboliteStore } from "../../../../store/contexts/metabolite.context";
import EditMetaboliteDialog from "./components/EditMetaboliteDialog";
import EditRelationsMetaboliteDialog from "./components/EditRelationsMetabolite";
import MetaboliteTable from "./components/MetaboliteTable";
import { Calculate, Refresh, Update } from "@mui/icons-material";
import { toast } from "react-toastify";
import api from "../../../../utils/api/api";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { socket } from "../../../../App";

const MetabolitePage = () => {
  const { metabolites, subcategoriesScore, fetchMetaboliteData } =
    useMetaboliteStore();

  const [metaboliteNameSearch, setMetaboliteNameSearch] = useState("");
  const [recalculatingMetabolites, setRecalculatingMetabolite] = useState(false)

  const [isOpenEditDialog, setIsOpenEditDialog] = useState(false);
  const [isOpenEditRelationsDialog, setIsOpenEditRelationsDialog] =
    useState(false);
  const [selectedMetaboliteId, setSelectedMetaboliteId] = useState<number>(0);
  const [subcategoryScoreSelected, setSubcategoryScoreSelected] = useState<
    number | null
  >(null);
  const [isLoadingRecalculateRange, setIsLoadingRecalculateRange] =
    useState(false);

  const handleChangeMetaboliteNameSearch = (event: any) => {
    setMetaboliteNameSearch(event.target.value);
  };

  const handleSelectSubcategoryScore = (
    event: any,
    newValue: ScoreSubcategory | null
  ) => {
    setSubcategoryScoreSelected(newValue?.id ?? null);
  };

  const handleCloseEditDialog = () => {
    setSelectedMetaboliteId(0);
    setIsOpenEditDialog(false);
  };

  const handleEditMetabolite = useCallback((id: number) => {
    setSelectedMetaboliteId(id);
    setIsOpenEditDialog(true);
    // fecha outros dialogs:
    setIsOpenEditRelationsDialog(false);
  }, []);

  const handleEditMetaboliteScoreSubcategory = useCallback((id: number) => {
    setSelectedMetaboliteId(id);
    setIsOpenEditRelationsDialog(true);
    // fecha outros dialogs:
    setIsOpenEditDialog(false);
  }, []);

  const handleCloseMetaboliteScoreSubcategoryDialog = () => {
    setSelectedMetaboliteId(0);
    setIsOpenEditRelationsDialog(false);
  };

  const authStore = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    socket.on("RecalculatedReferenceRanges", (data) => {
      setRecalculatingMetabolite(data?.data.status === 'in-progress' ? true : false)

      if (data?.status === 200 && data?.data.status !== 'in-progress') {
        toast.success("Range recalculated successfully.");
        window.location.reload();
      }
    });
  }, []);

  const onUpdate = async () => {
    try {
      setIsLoadingRecalculateRange(true);

      const { status } = await api.post(
        "/backoffice/metabolite/recalculate-range",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authStore.token}`,
          },
        }
      );

      if (status === 201) {
        toast.success("Range recalculated in progress...");
      }
    } catch (err: any) {
      if (err.response && err.response.data.message)
        toast.error(err.response.data.message);
    } finally {
      setIsLoadingRecalculateRange(false);
    }
  };

  useEffect(() => {
    if (subcategoryScoreSelected) {
      const scoreSubcategory = subcategoriesScore.find(
        (subcategory) => subcategory.id === subcategoryScoreSelected
      );

      if (scoreSubcategory) {
        const filtered = metabolites.filter((metabolite) =>
          scoreSubcategory.metabolitesIds?.includes(metabolite.id)
        );
        setFilteredList(filtered);

        return;
      }

      return;
    }

    setFilteredList(metabolites);
  }, [subcategoryScoreSelected, metabolites, subcategoriesScore]);

  useEffect(() => {
    if (metaboliteNameSearch) {
      const filtered = metabolites.filter(
        (metabolite) =>
          metabolite.name
            .toLowerCase()
            .includes(metaboliteNameSearch.toLowerCase()) ||
          metabolite.otherNames?.some((otherName) =>
            otherName.toLowerCase().includes(metaboliteNameSearch.toLowerCase())
          )
      );
      setFilteredList(filtered);

      return;
    }

    setFilteredList(metabolites);
  }, [metaboliteNameSearch, metabolites]);

  const [filteredList, setFilteredList] = useState<Metabolite[]>([]);

  return (
    <>
      <Header>
        <Typography level="body-lg" fontSize="xl">
          Metabolite
        </Typography>
      </Header>
      <div className="p-4 rounded">
        <div className="flex gap-2 mb-4">
          <div className="flex w-96 ">
            <Autocomplete
              sx={{
                width: "100%",
              }}
              placeholder="Filter by subcategory score"
              options={subcategoriesScore}
              getOptionLabel={(option) => option.name}
              onChange={handleSelectSubcategoryScore}
              groupBy={(option) => option.category}
              renderGroup={(params) => (
                <div>
                  <strong className="ml-1 text-lg font-semibold">
                    {params.group}
                  </strong>
                  {params.children}
                </div>
              )}
            />
          </div>
          <div className="flex w-96 ">
            <Input
              sx={{
                width: "100%",
              }}
              placeholder="Filter by name"
              value={metaboliteNameSearch}
              onChange={handleChangeMetaboliteNameSearch}
            />
          </div>
          <Button
            variant="solid"
            color="success"
            sx={{
              marginLeft: "auto",
            }}
            startDecorator={recalculatingMetabolites ? <CircularProgress /> : <Update />}
            onClick={onUpdate}
            loading={isLoadingRecalculateRange}
            disabled={isLoadingRecalculateRange || recalculatingMetabolites}
          >
            {recalculatingMetabolites ? 'Recalculating' : 'Recalculate Range'}
          </Button>
        </div>
        <Box sx={{ width: "100%" }}>
          <MetaboliteTable
            list={filteredList}
            onEditMetabolite={handleEditMetabolite}
            onEditMetaboliteScoreSubcategory={
              handleEditMetaboliteScoreSubcategory
            }
          />
        </Box>
      </div>
      <EditMetaboliteDialog
        onClose={handleCloseEditDialog}
        open={isOpenEditDialog && selectedMetaboliteId > 0}
        metaboliteId={selectedMetaboliteId}
      />
      <EditRelationsMetaboliteDialog
        onClose={handleCloseMetaboliteScoreSubcategoryDialog}
        open={isOpenEditRelationsDialog && selectedMetaboliteId > 0}
        metaboliteId={selectedMetaboliteId}
      />
    </>
  );
};

export default MetabolitePage;
