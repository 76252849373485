import {
  Box,
  Button,
  DialogTitle,
  LinearProgress,
  Modal,
  ModalClose,
  ModalDialog,
  Typography,
} from "@mui/joy";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { RootState } from "../../../../../../store/store";
import api from "../../../../../../utils/api/api";
import {
  CheckCircle,
  Close,
  CloudUpload,
  FilePresent,
} from "@mui/icons-material";
import { socket } from "../../../../../../App";
import { CircularProgress } from "@mui/material";

interface UploadResultsDialogProps {
  open: boolean;
  onClose: () => void;
  fetchRecipientData: () => void;
}

const UploadResultsDialog = ({
  open,
  onClose,
  fetchRecipientData,
}: UploadResultsDialogProps) => {
  const [file, setFile] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);
  const [wasUpload, setWasUpload] = useState(false);
  const [responseProccess, setResponseProccess] = useState<{
    errors: number[];
    processed: number[];
  } | null>(null);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files) {
      const _file = files[0];

      if (
        _file?.type !==
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        return toast.error("Invalid file type. Select only xlsx files.");
      }

      setFile(_file);
    }
  };

  const authStore = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    socket.on("ProcessedResultsFile", (data) => {
      if (data.status === 200 && data.data.status === 'finished') {
        setResponseProccess(data.data.recipients);
      }

      if (data.status !== 200 && data.data.status === 'finished') {
        toast.error(data.message);
      }
    });
  }, []);

  const handleClose = () => {
    onClose();
    setFile(null);
    setWasUpload(false);
    setResponseProccess(null);
    fetchRecipientData();
  };

  const onSend = async () => {
    try {
      setLoading(true);
      const formData = new FormData();

      if (!file) return;

      formData.append("file", file);

      const { data } = await api.post(
        "/backoffice/results/upload-result",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${authStore.token}`,
          },
        }
      );
      setWasUpload(data);
    } catch (err: any) {
      if (err.response && err.response.data.message)
        toast.error(err.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <ModalDialog
        layout="center"
        style={{
          width: "540px",
          minHeight: "320px",
        }}
      >
        <ModalClose />
        <DialogTitle>Upload Results</DialogTitle>

        {loading ? (
          <div className="w-full h-full flex justify-center items-center flex-1">
            <LinearProgress />
          </div>
        ) : !file ? (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              border: "1px dashed #C4C4C4",
              borderRadius: "8px",
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "8px",
              cursor: "pointer",
              flexGrow: 1,
            }}
          >
            <input
              ref={fileInputRef}
              type="file"
              multiple={false}
              onChange={handleFileSelection}
              className="opacity-0 w-full h-full absolute cursor-pointer"
            />

            <div className="flex flex-col items-center justify-center gap-4">
              <CloudUpload sx={{ height: 48, width: 48 }} />
              <Typography>
                Click and Select or Drag and Drop xlsx file
              </Typography>
            </div>
          </Box>
        ) : file && !wasUpload ? (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              border: "1px dashed #C4C4C4",
              borderRadius: "8px",
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "8px",
              cursor: "pointer",
              flexGrow: 1,
            }}
          >
            <div className="flex flex-col items-center justify-center gap-4 px-4">
              <FilePresent sx={{ height: 48, width: 48 }} />
              <div className="flex items-center gap-2">
                <Typography>{file.name}</Typography>
                <Close onClick={() => setFile(null)} sx={{ color: "red" }} />
              </div>
            </div>
          </Box>
        ) : wasUpload && !responseProccess ? (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              flex: 1,
            }}
          >
            <CheckCircle
              sx={{ color: "green", fontSize: 64, marginBottom: "16px" }}
            />
            <Typography sx={{ marginBottom: "1.25rem", textAlign: "center" }}>
              File sent for processing. Once finished, this screen will be
              automatically updated.
            </Typography>
            <div className="w-full flex justify-between items-center gap-4">
              <LinearProgress />
            </div>
          </Box>
        ) : (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography sx={{ marginBottom: "16px" }}>
              Processed recipients:
            </Typography>
            <Box
              sx={{
                display: "grid",
                gap: "16px",
                gridTemplateColumns: "1fr 1fr",
                overflowY: "auto",
                maxHeight: "54vh",
              }}
            >
              {responseProccess?.processed.map((recipientId) => (
                <p className="flex gap-2 items-center">
                  <div className="w-2 h-2 rounded-full bg-green-700" />
                  {recipientId}
                </p>
              ))}
              {responseProccess?.errors.map((recipientId) => (
                <p className="flex gap-2 items-center">
                  <div className="w-2 h-2 rounded-full bg-red-700" />
                  {recipientId}
                </p>
              ))}
            </Box>
            <div className="flex items-center gap-4 justify-end text-xs mt-4 pr-4">
              Status legend:
              <p className="flex gap-2 items-center">
                <div className="w-2 h-2 rounded-full bg-green-700" />
                Success
              </p>
              <p className="flex gap-2 items-center">
                <div className="w-2 h-2 rounded-full bg-red-700" />
                Error
              </p>
            </div>
          </Box>
        )}
        {file && !wasUpload && !loading && (
          <Button
            variant="solid"
            sx={{ marginLeft: "auto", minWidth: 130 }}
            onClick={onSend}
          >
            Send
          </Button>
        )}
      </ModalDialog>
    </Modal>
  );
};

export default UploadResultsDialog;
