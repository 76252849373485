import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  DialogTitle,
  FormControl,
  FormLabel,
  Grid,
  LinearProgress,
  Modal,
  ModalClose,
  ModalDialog,
  Option,
  Select,
} from "@mui/joy";
import { useEffect, useMemo, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useRecipientStore } from "../../../../../store/contexts/recipient.context";
import { RootState } from "../../../../../store/store";
import api from "../../../../../utils/api/api";
import useDebounce from "../../../../../utils/hooks/useDebounce";

interface LabEditRecipientDialogProps {
  open: boolean;
  onClose: () => void;
  recipientId: number;
}

const LabEditRecipientDialog = ({
  open,
  onClose,
  recipientId,
}: LabEditRecipientDialogProps) => {
  const [loading, setLoading] = useState(false);
  const authStore = useSelector((state: RootState) => state.auth);
  const { updateOneRecipient, recipientStatus, getRecipientById } =
    useRecipientStore();

  const handleClose = () => {
    onClose();
  };

  const debouncedLoading = useDebounce(loading, 800);

  const formInputNames = useMemo(() => {
    return {
      status: "status",
    };
  }, []);

  const recipientSchema = yup.object().shape({
    [formInputNames.status]: yup.number(),
  });

  const {
    handleSubmit,
    formState: { isDirty },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(recipientSchema),
  });

  useEffect(() => {
    const recipient = getRecipientById(recipientId);
    if (recipient) {
      const status = recipientStatus.filter(
        (status) =>
          status.status.toLowerCase() === recipient.status.toLowerCase()
      );

      if (status.length > 0) setValue(formInputNames.status, status[0].id);
    }
  }, [
    recipientId,
    getRecipientById,
    setValue,
    formInputNames,
    recipientStatus,
  ]);

  const handleChangeStatus = (
    event: React.SyntheticEvent | null,
    newValue: number | null
  ) => {
    if (!newValue) return;
    setValue(formInputNames.status, newValue, {
      shouldDirty: true,
    });
  };

  const onSubmit = async (data: FieldValues) => {
    setLoading(true);
    try {
      const response = await api.post(
        `/backoffice/recipient/update/${recipientId}`,
        {
          statusId: data.status,
        },
        {
          headers: {
            Authorization: `Bearer ${authStore.token}`,
          },
        }
      );

      updateOneRecipient(response.data);
      toast.success("Recipient updated successfully");
      onClose();
    } catch (err: any) {
      console.log(err);
      toast.error("Error on update recipient: " + err.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <ModalDialog layout="center">
        <ModalClose />
        <DialogTitle>Edit Recipient</DialogTitle>
        {loading || debouncedLoading || !(recipientId > 0) ? (
          <div className="w-[500px]">
            <LinearProgress />
          </div>
        ) : (
          <form className="w-[500px]" onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2} sx={{ flexGrow: 1, width: "100%" }}>
              <Grid xs={12}>
                <FormControl>
                  <FormLabel>Status</FormLabel>
                  <Select
                    variant="soft"
                    placeholder="Select status"
                    sx={{ width: "100%" }}
                    onChange={handleChangeStatus}
                    value={Number(watch(formInputNames.status))}
                    // @ts-ignore
                  >
                    {recipientStatus.map((method) => (
                      <Option key={method.id} value={method.id}>
                        {method.status}
                      </Option>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid xs={12} sm={12} sx={{ width: "100%", display: "flex" }}>
                <Button
                  disabled={!isDirty}
                  type="submit"
                  variant="soft"
                  sx={{
                    marginLeft: "auto",
                  }}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </ModalDialog>
    </Modal>
  );
};

export default LabEditRecipientDialog;
