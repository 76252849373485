import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  DialogTitle,
  FormControl,
  FormLabel,
  Grid,
  Input,
  LinearProgress,
  Modal,
  ModalClose,
  ModalDialog,
  Option,
  Select,
} from "@mui/joy";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as yup from "yup";
import { Metabolite } from "../../../../../@types";
import { useMetaboliteStore } from "../../../../../store/contexts/metabolite.context";
import { RootState } from "../../../../../store/store";
import api from "../../../../../utils/api/api";
import useDebounce from "../../../../../utils/hooks/useDebounce";

interface EditMetaboliteDialogProps {
  open: boolean;
  onClose: () => void;
  metaboliteId: number;
}

const EditMetaboliteDialog = ({
  open,
  onClose,
  metaboliteId,
}: EditMetaboliteDialogProps) => {
  const [loading, setLoading] = useState(true);
  const authStore = useSelector((state: RootState) => state.auth);
  const { categories, updateOneMetabolite } = useMetaboliteStore();

  const debouncedLoading = useDebounce(loading, 800);

  const formInputNames = useMemo(() => {
    return {
      name: "name",
      minRange: "referenceRangeMin",
      maxRange: "referenceRangeMax",
      molarMass: "molarMass",
      pka: "pka",
      logp: "logp",
      metaboliteCategory: "categoryId",
    };
  }, []);

  const metaboliteSchema = yup.object().shape({
    [formInputNames.name]: yup.string().required("Campo obrigatório"),
    [formInputNames.minRange]: yup
      .number()
      .required("Campo obrigatório")
      .min(0, "Valor mínimo: 0"),
    [formInputNames.maxRange]: yup
      .number()
      .required("Campo obrigatório")
      .min(0, "Valor mínimo: 0"),
    [formInputNames.molarMass]: yup.number(),
    [formInputNames.pka]: yup.string(),
    [formInputNames.logp]: yup.number(),
    [formInputNames.metaboliteCategory]: yup
      .number()
      .required("Campo obrigatório"),
  });

  const {
    register,
    handleSubmit,
    formState: { isDirty },
    setValue,
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(metaboliteSchema),
  });

  const handleClose = useCallback(() => {
    onClose();
    reset();
  }, [onClose, reset]);

  const onSubmit = async (values: FieldValues) => {
    try {
      const response = await api.post(
        `/backoffice/metabolite/update-data/${metaboliteId}`,
        {
          ...values,
        },
        {
          headers: {
            Authorization: `Bearer ${authStore.token}`,
          },
        }
      );

      const metabolite = response.data;
      updateOneMetabolite(metabolite as Metabolite);
      toast.success("Metabolite updated successfully");
      handleClose();
    } catch (err: any) {
      console.log(err);
      if (err.response && err.response.data.message)
        toast.error("Error on update metabolite: " + err.response.data.message);
    }
  };

  const handleChangeCategory = (
    event: React.SyntheticEvent | null,
    newValue: number | null
  ) => {
    if (!newValue) return;
    setValue(formInputNames.metaboliteCategory, newValue, {
      shouldDirty: true,
    });
  };

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const [responseMetabolite] = await Promise.all([
          api.get(`/backoffice/metabolite/${metaboliteId}`, {
            headers: {
              Authorization: `Bearer ${authStore.token}`,
            },
          }),
        ]);

        setValue(formInputNames.name, responseMetabolite.data.name);
        setValue(
          formInputNames.minRange,
          responseMetabolite.data.referenceRangeMin
        );
        setValue(
          formInputNames.maxRange,
          responseMetabolite.data.referenceRangeMax
        );
        setValue(formInputNames.molarMass, responseMetabolite.data.molarMass);
        setValue(formInputNames.pka, responseMetabolite.data.pka);
        setValue(formInputNames.logp, responseMetabolite.data.logp);
        setValue(
          formInputNames.metaboliteCategory,
          responseMetabolite.data.categoryComplete.id + ""
        );
      } catch (err: any) {
        console.log(err);
        if (err.response && err.response.data.message)
          toast.error(
            "Error on fetch metabolite data: " + err.response.data.message
          );
        handleClose();
      } finally {
        setLoading(false);
      }
    };

    if (open && metaboliteId > 0) fetchData();
  }, [
    open,
    metaboliteId,
    authStore.token,
    formInputNames,
    setValue,
    handleClose,
  ]);

  return (
    <Modal open={open} onClose={handleClose}>
      <ModalDialog layout="center">
        <ModalClose />
        <DialogTitle>Edit Metabolite</DialogTitle>
        {loading || debouncedLoading || !(metaboliteId > 0) ? (
          <div className="w-[500px]">
            <LinearProgress />
          </div>
        ) : (
          <form className="w-[500px]" onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2} sx={{ flexGrow: 1, width: "100%" }}>
              <Grid xs={12}>
                <FormControl>
                  <FormLabel>Name</FormLabel>
                  <Input variant="soft" {...register(formInputNames.name)} />
                </FormControl>
              </Grid>
              <Grid xs={12} sm={6} md={6}>
                <FormControl>
                  <FormLabel>Min. Range</FormLabel>
                  <Input
                    variant="soft"
                    {...register(formInputNames.minRange, {
                      valueAsNumber: true,
                    })}
                  />
                </FormControl>
              </Grid>
              <Grid xs={12} sm={6} md={6}>
                <FormControl>
                  <FormLabel>Max. Range</FormLabel>
                  <Input
                    variant="soft"
                    {...register(formInputNames.maxRange, {
                      valueAsNumber: true,
                    })}
                  />
                </FormControl>
              </Grid>
              <Grid xs={12} sm={6} md={6}>
                <FormControl>
                  <FormLabel>Molar Mass</FormLabel>
                  <Input
                    variant="soft"
                    {...register(formInputNames.molarMass)}
                  />
                </FormControl>
              </Grid>
              <Grid xs={12} sm={6} md={6}>
                <FormControl>
                  <FormLabel>PKA</FormLabel>
                  <Input variant="soft" {...register(formInputNames.pka)} />
                </FormControl>
              </Grid>
              <Grid xs={12} sm={6} md={4}>
                <FormControl>
                  <FormLabel>logp</FormLabel>
                  <Input variant="soft" {...register(formInputNames.logp)} />
                  {/* <FormHelperText>Min Length: 8 </FormHelperText> */}
                </FormControl>
              </Grid>
              <Grid xs={12} sm={6} md={8}>
                <FormControl>
                  <FormLabel>Metabolite Category</FormLabel>
                  <Select
                    variant="soft"
                    name={formInputNames.metaboliteCategory}
                    onChange={handleChangeCategory}
                    value={Number(watch(formInputNames.metaboliteCategory))}
                  >
                    {categories.map((cat) => (
                      <Option key={cat.id} value={cat.id}>
                        {cat.category}
                      </Option>
                    ))}
                  </Select>
                  {/* <FormHelperText>Min Length: 8 </FormHelperText> */}
                </FormControl>
              </Grid>
              <Grid xs={12} sm={12} sx={{ width: "100%", display: "flex" }}>
                <Button
                  disabled={!isDirty}
                  type="submit"
                  variant="soft"
                  sx={{
                    marginLeft: "auto",
                  }}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </ModalDialog>
    </Modal>
  );
};

export default EditMetaboliteDialog;
