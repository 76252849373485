import { createSlice } from "@reduxjs/toolkit";

type User = {
  id: number;
  isFirstAccess: boolean;
  hasAdminAuth: boolean;
  hasLabAuth: boolean;
  email: string;
  name: string;
};

interface IAuthState {
  isAuthenticated: boolean;
  user?: User;
  token: string;
}

const initialState: IAuthState = {
  isAuthenticated: false,
  user: undefined,
  token: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    handlePasswordChange: (
      state,
      action: {
        payload: {
          user: User;
        };
      }
    ) => {
      return {
        isAuthenticated: true,
        user: action.payload.user,
        token: state.token,
      };
    },
    handleLogin: (
      _,
      action: {
        payload: {
          user: User;
          token: string;
        };
      }
    ) => {
      return {
        isAuthenticated: true,
        user: action.payload.user,
        token: action.payload.token,
      };
    },
    handleLogout: () => {
      return initialState;
    },
  },
});

export const { handleLogin, handleLogout, handlePasswordChange } = authSlice.actions;

export default authSlice.reducer;
