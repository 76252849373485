import React, { Fragment, useCallback, useMemo, useState } from "react";
import type { ScoreNutrientByRecipient } from "../../../../../@types";
import { Box } from "@mui/joy";
import { Sheet, Table } from "@mui/joy";

interface ScoresTableProps {
  scores: ScoreNutrientByRecipient[];
}

export const ScoresTable: React.FC<ScoresTableProps> = ({ scores }) => {
  const [sortColumn, setSortColumn] = useState<
    keyof ScoreNutrientByRecipient | null
  >(null);
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");

  const onSortChange = useCallback(
    (column: keyof ScoreNutrientByRecipient) => {
      if (sortColumn === column) {
        setSortDirection(sortDirection === "asc" ? "desc" : "asc");
      } else {
        setSortColumn(column);
        setSortDirection("asc");
      }
    },
    [sortColumn, sortDirection]
  );

  const sortedStats = useMemo(() => {
    return scores?.sort((first, second) => {
      if (!sortColumn) return 0;

      if (first[sortColumn] < second[sortColumn]) {
        return sortDirection === "asc" ? -1 : 1;
      }

      if (first[sortColumn] > second[sortColumn]) {
        return sortDirection === "asc" ? 1 : -1;
      }

      return 0;
    });
  }, [sortColumn, sortDirection, scores]);

  const memoizedTable = useMemo(() => {
    return (
      <Sheet
        variant="outlined"
        sx={{
          borderRadius: 4,
          "--TableCell-height": "40px",
          "--TableHeader-height": "calc(1 * var(--TableCell-height))",
          "--Table-firstColumnWidth": "80px",
          "--Table-lastColumnWidth": "144px",
          "--TableRow-stripeBackground": "rgba(0 0 0 / 0.04)",
          "--TableRow-hoverBackground": "rgba(0 0 0 / 0.08)",
          overflow: "auto",
          background: (
            theme
          ) => `linear-gradient(to right, ${theme.vars.palette.background.surface} 30%, rgba(255, 255, 255, 0)),
            linear-gradient(to right, rgba(255, 255, 255, 0), ${theme.vars.palette.background.surface} 70%) 0 100%,
            radial-gradient(
              farthest-side at 0 50%,
              rgba(0, 0, 0, 0.12),
              rgba(0, 0, 0, 0)
            ),
            radial-gradient(
                farthest-side at 100% 50%,
                rgba(0, 0, 0, 0.12),
                rgba(0, 0, 0, 0)
              )
              0 100%`,
          backgroundSize:
            "40px calc(100% - var(--TableCell-height)), 40px calc(100% - var(--TableCell-height)), 14px calc(100% - var(--TableCell-height)), 14px calc(100% - var(--TableCell-height))",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "local, local, scroll, scroll",
          backgroundPosition:
            "var(--Table-firstColumnWidth) var(--TableCell-height), calc(100% - var(--Table-lastColumnWidth)) var(--TableCell-height), var(--Table-firstColumnWidth) var(--TableCell-height), calc(100% - var(--Table-lastColumnWidth)) var(--TableCell-height)",
          backgroundColor: "background.surface",
        }}
      >
        <Table
          borderAxis="bothBetween"
          stripe="odd"
          hoverRow
          sx={{
            "& tr > *:first-of-type": {
              position: "sticky",
              left: 0,
              boxShadow: "1px 0 var(--TableCell-borderColor)",
              bgcolor: "background.surface",
            },
          }}
        >
          <thead>
            <tr>
              <th style={{ width: 25 }}>
                <button onClick={() => onSortChange("id")}>Id</button>
              </th>
              <th style={{ width: 65 }}>
                <button onClick={() => onSortChange("reloadScore")}>
                  Reload Score
                </button>
              </th>
              <th style={{ width: 65 }}>
                <button onClick={() => onSortChange("digestionScore")}>
                  Digestion Score
                </button>
              </th>
              <th style={{ width: 65 }}>
                <button onClick={() => onSortChange("energyScore")}>
                  Energy Score
                </button>
              </th>
              <th style={{ width: 65 }}>
                <button onClick={() => onSortChange("antioxidantScore")}>
                  Antioxidant Score
                </button>
              </th>
              <th style={{ width: 65 }}>
                <button onClick={() => onSortChange("messagingScore")}>
                  Messaging Score
                </button>
              </th>
              <th style={{ width: 65 }}>
                <button onClick={() => onSortChange("detoxScore")}>
                  Detox Score
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            {sortedStats?.length ? (
              sortedStats?.map((row) => (
                <Fragment key={row.id}>
                  <tr>
                    <td className="text-xs">
                      {String(row.id).padStart(3, "0")}
                    </td>
                    <td className="text-sm">{row.reloadScore}</td>
                    <td className="text-sm">{row.digestionScore}</td>
                    <td className="text-sm">{row.energyScore}</td>
                    <td className="text-sm">{row.antioxidantScore}</td>
                    <td className="text-sm">{row.messagingScore}</td>
                    <td className="text-sm">{row.detoxScore}</td>
                  </tr>
                </Fragment>
              ))
            ) : (
              <tr>
                <td colSpan={7}>
                  <Box>
                    <p>Not found results</p>
                  </Box>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Sheet>
    );
  }, [onSortChange, sortedStats]);

  return memoizedTable;
};
