import { Refresh } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Button,
  LinearProgress,
  Option,
  Select,
  Typography,
} from "@mui/joy";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Recipient } from "../../../../@types";
import Header from "../../../../components/layout/Header";
import { useRecipientStore } from "../../../../store/contexts/recipient.context";
import { RootState } from "../../../../store/store";
import AddRecipientsDialog from "./components/AddRecipientsDialog";
import EditRecipientDialog from "./components/EditRecipientDialog";
import RecipientsTable from "./components/RecipientsTable";

const RecipientsPage = () => {
  const authStore = useSelector((state: RootState) => state.auth);
  const [isOpenAddRecipients, setIsOpenAddRecipients] = useState(false);

  const { recipients, recipientStatus, fetchRecipientData, loadingRecipients } =
    useRecipientStore();

  const [filteredList, setFilteredList] = useState<Recipient[]>([]);
  const [selectedRecipientId, setSelectedRecipientId] = useState<number>(0);
  const [isOpenEditDialog, setIsOpenEditDialog] = useState(false);

  const [statusSelected, setStatusSelected] = useState("");

  const handleChangeStatus = (
    event: React.SyntheticEvent | null,
    newValue: string | null
  ) => {
    if (!newValue) {
      setStatusSelected("");
      return;
    }
    setStatusSelected(newValue);
  };

  const handleEditRecipient = useCallback((id: number) => {
    setSelectedRecipientId(id);
    setIsOpenEditDialog(true);
  }, []);

  const handleCloseEditRecipient = () => {
    setSelectedRecipientId(0);
    setIsOpenEditDialog(false);
  };

  const handleAddRecipient = () => {
    setIsOpenAddRecipients(true);
  };

  const handleCloseAddRecipient = () => {
    setIsOpenAddRecipients(false);
  };

  useEffect(() => {
    let _recipients = recipients;

    if (statusSelected) {
      _recipients = recipients.filter(
        (recipient) => recipient.status === statusSelected
      );
    }
    setFilteredList(_recipients);
  }, [recipients, statusSelected]);

  return (
    <>
      <AddRecipientsDialog
        open={isOpenAddRecipients}
        onClose={handleCloseAddRecipient}
      />
      <Header>
        <Typography level="body-lg" fontSize="xl">
          Recipients
        </Typography>
        <Button
          color="primary"
          startDecorator={<Refresh />}
          variant="outlined"
          sx={{ ml: "auto" }}
          onClick={fetchRecipientData}
        >
          Refresh
        </Button>
      </Header>
      <div className="p-4 rounded">
        <div className="mb-4">
          <div className="flex gap-2 ">
            <div className="flex w-96 ">
              <Select
                variant="soft"
                placeholder="Select status"
                sx={{ width: "100%" }}
                onChange={handleChangeStatus}
                value={statusSelected}
              >
                <Option value={null}>
                  <em>All</em>
                </Option>
                {recipientStatus.map((method) => (
                  <Option key={method.id} value={method.status}>
                    {method.status}
                  </Option>
                ))}
              </Select>
            </div>

            {authStore.user?.hasAdminAuth && (
              <Button
                color="success"
                startDecorator={<AddIcon />}
                sx={{ ml: "auto" }}
                onClick={handleAddRecipient}
              >
                Create New
              </Button>
            )}
          </div>
        </div>
        <Box sx={{ width: "100%" }}>
          {loadingRecipients ? (
            <div className="">
              <LinearProgress />
            </div>
          ) : (
            <RecipientsTable
              list={filteredList}
              onEditRecipient={handleEditRecipient}
            />
          )}
        </Box>
      </div>
      <EditRecipientDialog
        onClose={handleCloseEditRecipient}
        open={isOpenEditDialog && selectedRecipientId > 0}
        recipientId={selectedRecipientId}
      />
    </>
  );
};

export default RecipientsPage;
