import { yupResolver } from "@hookform/resolvers/yup";
import { Button, FormControl, FormLabel, Grid, Input } from "@mui/joy";
import { useMemo, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { RootState } from "../../../../../../store/store";

interface InsertResultDialogSearchHeaderProps {
  onSubmit: (data: FieldValues) => void;
}

const InsertResultDialogSearchHeader = ({
  onSubmit,
}: InsertResultDialogSearchHeaderProps) => {
  const [loading, setLoading] = useState(false);
  const authStore = useSelector((state: RootState) => state.auth);

  const formInputNames = useMemo(() => {
    return {
      recipientId: "recipientId",
    };
  }, []);

  const recipientSchema = yup.object().shape({
    [formInputNames.recipientId]: yup.number().required("Campo obrigatório"),
  });

  const {
    register,
    handleSubmit,
    formState: { isDirty },
  } = useForm({
    resolver: yupResolver(recipientSchema),
  });

  const handleOnSubmit = (data: FieldValues) => {
    onSubmit(data);
  };

  return (
    <form className="" onSubmit={handleSubmit(handleOnSubmit)}>
      <Grid container spacing={2} sx={{ flexGrow: 1, width: "100%" }}>
        <Grid xs={12} sm={4}>
          <FormControl>
            <FormLabel>Recipient Number</FormLabel>
            <Input
              variant="soft"
              type="number"
              {...register(formInputNames.recipientId)}
            />
          </FormControl>
        </Grid>
        <Grid xs={12} sm={4} sx={{ marginTop: "auto" }}>
          <Button
            disabled={!isDirty}
            type="submit"
            variant="soft"
            sx={{
              marginLeft: "auto",
            }}
          >
            Search
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default InsertResultDialogSearchHeader;
