import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { Recipient, RecipientStatus } from "../../@types";
import api from "../../utils/api/api";
import { RootState } from "../store";

interface RecipientContextInterface {
  recipients: Recipient[];
  recipientStatus: RecipientStatus[];
  updateOneRecipient: (recipient: Recipient) => void;
  getRecipientById: (id: number) => Recipient | undefined;
  fetchRecipientData: () => Promise<void>;
  loadingRecipients: boolean;
  handleFreezeRecipient: (id: number) => Promise<void>;
}

export const RecipientContext = createContext<RecipientContextInterface>(
  {} as RecipientContextInterface
);

const RecipientProvider = ({ children }: { children: React.ReactNode }) => {
  const authStore = useSelector((state: RootState) => state.auth);

  const [loadingRecipients, setLoadingRecipients] = useState(false);

  const [recipients, setRecipients] = useState<Recipient[]>([]);
  const [recipientStatus, setRecipientStatus] = useState<RecipientStatus[]>([]);

  const getRecipientById = (id: number) => {
    return recipients.find((recipient) => recipient.id === id);
  };

  const updateOneRecipient = (recipient: Recipient) => {
    const recipientsUpdated = recipients.map((recipientItem) => {
      if (recipientItem.id === recipient.id) return recipient;

      return recipientItem;
    });

    setRecipients(recipientsUpdated);
  };

  const fetchRecipientData = useCallback(async () => {
    setLoadingRecipients(true);
    try {
      const [responseRecipient] = await Promise.all([
        api.get("backoffice/recipient/get-all", {
          headers: {
            Authorization: `Bearer ${authStore.token}`,
          },
        }),
      ]);

      const sorted = responseRecipient.data.sort(
        (a: Recipient, b: Recipient) => b.id - a.id
      );

      setRecipients(sorted);
    } catch (err) {
      setRecipients([]);
      console.log(err);
    } finally {
      setLoadingRecipients(false);
    }
  }, [authStore.token]);

  const handleFreezeRecipient = useCallback(
    async (recipientId: number) => {
      try {
        await api.post(
          "backoffice/recipient/freeze",
          { recipientId },
          {
            headers: {
              Authorization: `Bearer ${authStore.token}`,
            },
          }
        );

        await fetchRecipientData();
      } catch {}
    },
    [authStore.token, fetchRecipientData]
  );

  useEffect(() => {
    const fetchDataStatus = async () => {
      try {
        const [responseRecipientStatus] = await Promise.all([
          api.get("backoffice/recipient/get-all-recipient-status", {
            headers: {
              Authorization: `Bearer ${authStore.token}`,
            },
          }),
        ]);

        setRecipientStatus(responseRecipientStatus.data);
      } catch (err) {
        console.log(err);
      }
    };

    if (authStore.token) {
      fetchRecipientData();
      fetchDataStatus();
    }
  }, [authStore.token, fetchRecipientData]);

  return (
    <RecipientContext.Provider
      value={{
        recipients,
        updateOneRecipient,
        recipientStatus,
        getRecipientById,
        fetchRecipientData,
        loadingRecipients,
        handleFreezeRecipient,
      }}
    >
      {children}
    </RecipientContext.Provider>
  );
};

const useRecipientStore = () => {
  const context = useContext(RecipientContext);
  return context;
};

export { RecipientProvider, useRecipientStore };
