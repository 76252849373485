import { Refresh } from "@mui/icons-material";
import {
  Typography,
  Button,
  Box,
  LinearProgress,
  Autocomplete,
} from "@mui/joy";
import React from "react";
import Header from "../../../../components/layout/Header";
import { useScoreNutrientByRecipient } from "../../../../store/contexts/scoreNutrientsByRecipient.context";
import { ScoresTable } from "./components/ScoresTable";

export const ScoreNutrientByRecipient: React.FC = () => {
  const {
    loadingScores,
    scores,
    scoreSubCategories,
    setSelectedScoreSubCategory,
    handleFetchScores,
  } = useScoreNutrientByRecipient();

  return (
    <>
      <Header>
        <Typography level="body-lg" fontSize="xl">
          Scores By Recipient
        </Typography>
        <Button
          color="primary"
          startDecorator={<Refresh />}
          variant="outlined"
          sx={{ ml: "auto" }}
          onClick={handleFetchScores}
        >
          Refresh
        </Button>
      </Header>

      <div className="p-4 rounded">
        <div className="mb-4">
          <div className="flex">
            <div className="flex w-96">
              <Autocomplete
                sx={{
                  width: "100%",
                }}
                placeholder="Filter by subcategory score"
                options={scoreSubCategories}
                getOptionLabel={(option) => option.name}
                onChange={(event, value) => {
                  setSelectedScoreSubCategory(value?.id ?? null);
                }}
                groupBy={(option) => option.category}
                renderGroup={(params) => (
                  <div>
                    <strong className="ml-1 text-lg font-semibold">
                      {params.group}
                    </strong>
                    {params.children}
                  </div>
                )}
              />
            </div>
          </div>
        </div>
        <Box sx={{ width: "100%" }}>
          {loadingScores ? (
            <div className="">
              <LinearProgress />
            </div>
          ) : (
            <ScoresTable scores={scores} />
          )}
        </Box>
      </div>
    </>
  );
};
