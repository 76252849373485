import { Visibility } from "@mui/icons-material";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  IconButton,
  LinearProgress,
  Sheet,
  Table,
  Typography,
  Tooltip,
} from "@mui/joy";
import { Fragment, useCallback, useMemo, useState } from "react";
import { Freeze, Recipient } from "../../../../../@types";
import { EnumRecipientStatus } from "../../../../../utils/enum/recipient-status.enum";
import { TriangleAlert } from "lucide-react";

interface RecipientsTableProps {
  list: Recipient[];
  onEditRecipient: (id: number) => void;
  onSeeResult: (id: number) => void;
  onEditResult: (id: number) => void;
}

const RecipientsTable = ({
  list,
  onEditRecipient,
  onSeeResult,
  onEditResult,
}: RecipientsTableProps) => {
  const [sortColumn, setSortColumn] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");

  const onSortChange = useCallback(
    (column: string) => {
      if (sortColumn === column) {
        setSortDirection(sortDirection === "asc" ? "desc" : "asc");
      } else {
        setSortColumn(column);
        setSortDirection("asc");
      }
    },
    [sortColumn, sortDirection]
  );

  const getSpentTime = (freezes: Freeze[]) => {
    if (!freezes?.length) return null;

    let totalUnfreezeTime = 0;
    let lastUnfreezeTimestamp = null;

    for (const freeze of freezes) {
      const currentTimestamp = new Date(freeze.freezeAt).getTime();

      if (freeze.type === "UNFREEZE") lastUnfreezeTimestamp = currentTimestamp;

      if (freeze.type === "FREEZE" && lastUnfreezeTimestamp !== null) {
        totalUnfreezeTime += currentTimestamp - lastUnfreezeTimestamp;
        lastUnfreezeTimestamp = null;
      }
    }

    if (lastUnfreezeTimestamp !== null) {
      const now = new Date().getTime();
      totalUnfreezeTime += now - lastUnfreezeTimestamp;
    }

    const days = Math.floor(totalUnfreezeTime / (1000 * 60 * 60 * 24)) ?? 0;
    const remainingTime = totalUnfreezeTime % (1000 * 60 * 60 * 24);
    const hours = Math.floor(remainingTime / (1000 * 60 * 60)) ?? 0;
    const remainingAfterHours = remainingTime % (1000 * 60 * 60);
    const minutes = Math.floor(remainingAfterHours / (1000 * 60)) ?? 0;

    return {
      days,
      hours,
      minutes,
    };
  };

  const sortedList = useMemo(() => {
    return [...list].sort((a, b) => {
      if (!sortColumn) return 0;

      if (sortColumn === "timeSpent") {
        const firstSpentTime = getSpentTime(a.freezes);
        const secondSpentTime = getSpentTime(b.freezes);

        const firstSpentTimeInMinutes = firstSpentTime
          ? firstSpentTime.days * 1440 +
            firstSpentTime.hours * 60 +
            firstSpentTime.minutes
          : 0;

        const secondSpentTimeInMinutes = secondSpentTime
          ? secondSpentTime.days * 1440 +
            secondSpentTime.hours * 60 +
            secondSpentTime.minutes
          : 0;

        return sortDirection === "asc"
          ? firstSpentTimeInMinutes - secondSpentTimeInMinutes
          : secondSpentTimeInMinutes - firstSpentTimeInMinutes;
      }

      // @ts-ignore
      if (a[sortColumn] < b[sortColumn])
        return sortDirection === "asc" ? -1 : 1;
      // @ts-ignore
      if (a[sortColumn] > b[sortColumn])
        return sortDirection === "asc" ? 1 : -1;
      return 0;
    });
  }, [list, sortColumn, sortDirection]);

  const memoizedTable = useMemo(() => {
    return (
      <Sheet
        variant="outlined"
        sx={{
          borderRadius: 4,
          "--TableCell-height": "40px",
          "--TableHeader-height": "calc(1 * var(--TableCell-height))",
          "--Table-firstColumnWidth": "80px",
          "--Table-lastColumnWidth": "144px",
          "--TableRow-stripeBackground": "rgba(0 0 0 / 0.04)",
          "--TableRow-hoverBackground": "rgba(0 0 0 / 0.08)",
          overflow: "auto",
          background: (
            theme
          ) => `linear-gradient(to right, ${theme.vars.palette.background.surface} 30%, rgba(255, 255, 255, 0)),
            linear-gradient(to right, rgba(255, 255, 255, 0), ${theme.vars.palette.background.surface} 70%) 0 100%,
            radial-gradient(
              farthest-side at 0 50%,
              rgba(0, 0, 0, 0.12),
              rgba(0, 0, 0, 0)
            ),
            radial-gradient(
                farthest-side at 100% 50%,
                rgba(0, 0, 0, 0.12),
                rgba(0, 0, 0, 0)
              )
              0 100%`,
          backgroundSize:
            "40px calc(100% - var(--TableCell-height)), 40px calc(100% - var(--TableCell-height)), 14px calc(100% - var(--TableCell-height)), 14px calc(100% - var(--TableCell-height))",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "local, local, scroll, scroll",
          backgroundPosition:
            "var(--Table-firstColumnWidth) var(--TableCell-height), calc(100% - var(--Table-lastColumnWidth)) var(--TableCell-height), var(--Table-firstColumnWidth) var(--TableCell-height), calc(100% - var(--Table-lastColumnWidth)) var(--TableCell-height)",
          backgroundColor: "background.surface",
        }}
      >
        <Table
          borderAxis="bothBetween"
          stripe="odd"
          hoverRow
          sx={{
            "& tr > *:first-of-type": {
              position: "sticky",
              left: 0,
              boxShadow: "1px 0 var(--TableCell-borderColor)",
              bgcolor: "background.surface",
            },
            "& tr > *:last-child": {
              position: "sticky",
              right: 0,
              bgcolor: "var(--TableCell-headBackground)",
            },
          }}
        >
          <thead>
            <tr>
              <th style={{ width: "var(--Table-firstColumnWidth)" }}>Id</th>
              <th style={{ width: 200 }}>Status</th>
              <th style={{ width: 65 }}>
                <button onClick={() => onSortChange("timeSpent")}>
                  Time Spent
                </button>
              </th>
              <th style={{ width: 65 }}>Results Progress</th>
              <th
                aria-label="last"
                style={{ width: "var(--Table-lastColumnWidth)" }}
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {sortedList.map((row) => {
              const spentTime = getSpentTime(row.freezes);

              const totalSpentTimeInDays =
                (spentTime?.days ?? 0) +
                (spentTime?.hours ?? 0) / 24 +
                (spentTime?.minutes ?? 0) / (24 * 60);

              const progressResult =
                !!row.total_metabolites && !!row.total_results
                  ? (row.total_results / row.total_metabolites) * 100
                  : 0;

              return (
                <Fragment key={row.id}>
                  <tr>
                    <td>
                      <div className="flex justify-between items-center">
                        <span>{String(row.id).padStart(3, "0")}</span>
                        {totalSpentTimeInDays > 10 && (
                          <Tooltip title="Total spent time greater than 10 days">
                            <TriangleAlert className="text-red-500" />
                          </Tooltip>
                        )}
                        {row.hasCreatinine && (
                          <Tooltip title="Results values divided by creatinine value">
                            <div className="h-4 w-4 rounded-full bg-yellow-500"></div>
                          </Tooltip>
                        )}
                      </div>
                    </td>
                    <td className="w-[90px]">
                      <span className="text-xs text-ellipsis">
                        {row.status.toUpperCase().split("_").join(" ")}
                      </span>
                    </td>
                    <td className="text-xs">
                      {spentTime
                        ? `${spentTime.days} days, ${spentTime.hours} hours, ${spentTime.minutes} minutes`
                        : "-"}
                    </td>
                    <td className="text-xs">
                      {progressResult ? (
                        <Box display="flex" alignItems="center" gap={2}>
                          <LinearProgress
                            determinate
                            value={progressResult}
                            sx={{ flexGrow: 1 }}
                          />
                          <Typography
                            sx={{
                              minWidth: 40,
                              textAlign: "center",
                              fontSize: "0.75rem",
                            }}
                          >
                            {`${Math.round(progressResult)}%`}
                          </Typography>
                        </Box>
                      ) : (
                        "-"
                      )}
                    </td>
                    <td>
                      <Box sx={{ display: "flex", gap: 1 }}>
                        <IconButton
                          size="sm"
                          color="neutral"
                          onClick={() => onEditRecipient(row.id)}
                        >
                          <EditIcon />
                        </IconButton>
                        {row.status.toLowerCase() ===
                          EnumRecipientStatus.ANALYSIS_COMPLETED.toLowerCase() && (
                          <IconButton
                            size="sm"
                            color="neutral"
                            onClick={() => onSeeResult(row.id)}
                          >
                            <Visibility />
                          </IconButton>
                        )}
                        {row.status.toLowerCase() ===
                          EnumRecipientStatus.UNDER_ANALYSIS.toLowerCase() && (
                          <IconButton
                            size="sm"
                            color="neutral"
                            onClick={() => onEditResult(row.id)}
                          >
                            <AnalyticsIcon />
                          </IconButton>
                        )}
                      </Box>
                    </td>
                  </tr>
                </Fragment>
              );
            })}
          </tbody>
        </Table>
      </Sheet>
    );
  }, [sortedList, onSortChange, onEditRecipient, onSeeResult, onEditResult]);

  return memoizedTable;
};

export default RecipientsTable;
