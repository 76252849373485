import EditIcon from "@mui/icons-material/Edit";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HubIcon from "@mui/icons-material/Hub";
import { Badge, Box, IconButton, Sheet, Table } from "@mui/joy";
import { Fragment, useMemo, useState } from "react";
import { Metabolite } from "../../../../../@types";

interface MetaboliteTableProps {
  list: Metabolite[];
  onEditMetabolite: (id: number) => void;
  onEditMetaboliteScoreSubcategory: (id: number) => void;
}

const MetaboliteTable = ({
  list,
  onEditMetabolite,
  onEditMetaboliteScoreSubcategory,
}: MetaboliteTableProps) => {
  const [openId, setOpenId] = useState<number | null>(null);

  const memoizedTable = useMemo(() => {
    const toggleOpen = (id: number) => {
      if (openId === id) {
        setOpenId(null);
        return;
      }
      setOpenId(id);
    };

    return (
      <Sheet
        variant="outlined"
        sx={{
          borderRadius: 4,
          "--TableCell-height": "40px",
          // the number is the amount of the header rows.
          "--TableHeader-height": "calc(1 * var(--TableCell-height))",
          "--Table-firstColumnWidth": "80px",
          "--Table-lastColumnWidth": "144px",
          // background needs to have transparency to show the scrolling shadows
          "--TableRow-stripeBackground": "rgba(0 0 0 / 0.04)",
          "--TableRow-hoverBackground": "rgba(0 0 0 / 0.08)",
          overflow: "auto",
          background: (
            theme
          ) => `linear-gradient(to right, ${theme.vars.palette.background.surface} 30%, rgba(255, 255, 255, 0)),
            linear-gradient(to right, rgba(255, 255, 255, 0), ${theme.vars.palette.background.surface} 70%) 0 100%,
            radial-gradient(
              farthest-side at 0 50%,
              rgba(0, 0, 0, 0.12),
              rgba(0, 0, 0, 0)
            ),
            radial-gradient(
                farthest-side at 100% 50%,
                rgba(0, 0, 0, 0.12),
                rgba(0, 0, 0, 0)
              )
              0 100%`,
          backgroundSize:
            "40px calc(100% - var(--TableCell-height)), 40px calc(100% - var(--TableCell-height)), 14px calc(100% - var(--TableCell-height)), 14px calc(100% - var(--TableCell-height))",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "local, local, scroll, scroll",
          backgroundPosition:
            "var(--Table-firstColumnWidth) var(--TableCell-height), calc(100% - var(--Table-lastColumnWidth)) var(--TableCell-height), var(--Table-firstColumnWidth) var(--TableCell-height), calc(100% - var(--Table-lastColumnWidth)) var(--TableCell-height)",
          backgroundColor: "background.surface",
        }}
      >
        <Table
          borderAxis="bothBetween"
          stripe="odd"
          hoverRow
          sx={{
            "& tr > *:first-of-type": {
              position: "sticky",
              left: 0,
              boxShadow: "1px 0 var(--TableCell-borderColor)",
              bgcolor: "background.surface",
            },
            "& tr > *:last-child": {
              position: "sticky",
              right: 0,
              bgcolor: "var(--TableCell-headBackground)",
            },
          }}
        >
          <thead>
            <tr>
              <th style={{ width: "var(--Table-firstColumnWidth)" }}>Id</th>
              <th style={{ width: 200 }}>Name</th>
              <th style={{ width: 200 }}>Min. Reference Range</th>
              <th style={{ width: 200 }}>Max. Reference Range</th>
              <th style={{ width: 200 }}>Category</th>
              <th
                aria-label="last"
                style={{ width: "var(--Table-lastColumnWidth)" }}
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {list.map((row) => (
              <Fragment key={row.name + row.id}>
                <tr key={row.name}>
                  <td>{row.id}</td>
                  <td>{row.name}</td>
                  <td>{row.referenceRangeMin?.toFixed(2)}</td>
                  <td>{row.referenceRangeMax?.toFixed(2)}</td>
                  <td>{row.category}</td>
                  <td>
                    <Box sx={{ display: "flex", gap: 1 }}>
                      <IconButton
                        size="sm"
                        color="neutral"
                        onClick={() => toggleOpen(row.id)}
                      >
                        {row.id === openId ? (
                          <ExpandLessIcon />
                        ) : (
                          <ExpandMoreIcon />
                        )}
                      </IconButton>
                      <IconButton
                        size="sm"
                        color="neutral"
                        onClick={() => onEditMetabolite(row.id)}
                      >
                        <EditIcon />
                      </IconButton>
                      <Badge
                        badgeContent={row.scoreSubcategories?.length}
                        size="sm"
                        variant="outlined"
                      >
                        <IconButton
                          size="sm"
                          color="neutral"
                          onClick={() =>
                            onEditMetaboliteScoreSubcategory(row.id)
                          }
                        >
                          <HubIcon />
                        </IconButton>
                      </Badge>
                    </Box>
                  </td>
                </tr>
                {row.id === openId && (
                  <tr>
                    <td colSpan={6}>
                      <Box sx={{ display: "flex", gap: 1, p: 2 }}>
                        <Box sx={{ width: 200, fontWeight: 600 }}>
                          Other Names:
                        </Box>
                        <Box sx={{ flexGrow: 1 }}>
                          {row.otherNames?.join(`, `)}
                        </Box>
                      </Box>
                      <Box sx={{ display: "flex", gap: 1, p: 2 }}>
                        <Box sx={{ width: 200, fontWeight: 600 }}>
                          Score Subcategories:
                        </Box>
                        <Box sx={{ flexGrow: 1 }}>
                          {row.scoreSubcategories?.join(`, `)}
                        </Box>
                      </Box>
                      <Box sx={{ display: "flex", gap: 1, p: 2 }}>
                        <Box sx={{ width: 200, fontWeight: 600 }}>
                          Molar Mass:
                        </Box>
                        <Box sx={{ flexGrow: 1 }}>{row.molarMass}</Box>
                      </Box>
                      <Box sx={{ display: "flex", gap: 1, p: 2 }}>
                        <Box sx={{ width: 200, fontWeight: 600 }}>Pka:</Box>
                        <Box sx={{ flexGrow: 1 }}>{row.pka}</Box>
                      </Box>
                    </td>
                  </tr>
                )}
              </Fragment>
            ))}
          </tbody>
        </Table>
      </Sheet>
    );
  }, [list, openId, onEditMetabolite, onEditMetaboliteScoreSubcategory]);

  return memoizedTable;
};

export default MetaboliteTable;
