import React, { createContext, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ResultMethod } from "../../@types";
import api from "../../utils/api/api";
import { RootState } from "../store";

interface ResultsContextInterface {
  resultMethods: ResultMethod[];
}

export const ResultsContext = createContext<ResultsContextInterface>(
  {} as ResultsContextInterface
);

const ResultsProvider = ({ children }: { children: React.ReactNode }) => {
  const authStore = useSelector((state: RootState) => state.auth);

  const [resultMethods, setResultMethods] = useState<ResultMethod[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [responseMethods] = await Promise.all([
          api.get("backoffice/results/get-methods", {
            headers: {
              Authorization: `Bearer ${authStore.token}`,
            },
          }),
        ]);

        setResultMethods(responseMethods.data);
      } catch (err) {
        console.log(err);
      }
    };

    if (authStore.token) fetchData();
  }, [authStore.token]);

  return (
    <ResultsContext.Provider
      value={{
        resultMethods,
      }}
    >
      {children}
    </ResultsContext.Provider>
  );
};

const useResultsStore = () => {
  const context = useContext(ResultsContext);
  return context;
};

export { ResultsProvider, useResultsStore };
