import { jwtDecode } from "jwt-decode";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FirstAccessDialog from "../../components/dialog/FirstAccessDialog";
import { handleLogin } from "../reducers/authSlice";
import { RootState } from "../store";

type AuthModalType = "first-access";

interface AuthContextInterface {}

export const AuthContext = createContext<AuthContextInterface>(
  {} as AuthContextInterface
);

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState<AuthModalType>();
  const authStore = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    if (authStore.user && authStore.user.isFirstAccess) {
      setOpenModal(true);
      setModalType("first-access");
    }

    if (!authStore.token || !authStore.user) {
      const loginResponse = localStorage.getItem("loginResponse");
      if (loginResponse) {
        const data = JSON.parse(loginResponse);

        const isTokenExpired = (token: string) => {
          const decodedToken: any = jwtDecode(token);
          console.log(decodedToken.exp * 1000);
          return Date.now() >= decodedToken.exp * 1000;
        };

        if (!data.accessToken || isTokenExpired(data.accessToken)) {
          return;
        }

        dispatch(
          handleLogin({
            token: data.accessToken,
            user: data.user,
          })
        );
      }
    }
  }, [authStore.token, authStore.user, dispatch]);

  return (
    <AuthContext.Provider value={{}}>
      {children}
      <FirstAccessDialog
        open={openModal && modalType === "first-access"}
        onClose={handleCloseModal}
      />
    </AuthContext.Provider>
  );
};

const useAuthStore = () => {
  const context = useContext(AuthContext);
  return context;
};

export { AuthProvider, useAuthStore };
