import { Button } from "@mui/joy";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { Metabolite } from "../../../../../../@types";
import InsertResultMetaboliteRow from "./InsertResultMetaboliteRow";

interface InsertResultTableProps {
  list: Metabolite[];
  onSave: (values: FieldValues) => void;
  formProps: UseFormReturn<FieldValues, any, undefined>;
  loadingSave: boolean;
  wasSaved: boolean;
  loadingPublish: boolean;
  onPublish: () => void;
}

const InsertResultTable = ({
  list,
  onSave,
  formProps,
  loadingSave,
  loadingPublish,
  onPublish,
  wasSaved,
}: InsertResultTableProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = formProps;

  const onSubmit = (values: FieldValues) => {
    onSave(values);
  };

  return (
    <form className="" onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col gap-2 overflow-y-auto h-96">
        {list.map((metabolite, index) => (
          <InsertResultMetaboliteRow
            key={metabolite.id}
            register={register}
            htmlId={`metabolite-result-${metabolite.id}`}
            errors={errors}
            metabolite={metabolite}
          />
        ))}
      </div>
      <div className="flex justify-between py-3">
        <Button
          variant="soft"
          color="danger"
          size="lg"
          loading={loadingPublish}
          onClick={onPublish}
          disabled={!wasSaved}
        >
          Publish
        </Button>
        <Button
          type="submit"
          variant="soft"
          size="lg"
          sx={{
            marginLeft: "auto",
          }}
          loading={loadingSave}
        >
          Save
        </Button>
      </div>
    </form>
  );
};

export default InsertResultTable;
