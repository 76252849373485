import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import {
  Accordion,
  AccordionDetails,
  AccordionGroup,
  AccordionSummary,
  Button,
  DialogTitle,
  LinearProgress,
  Modal,
  ModalClose,
  ModalDialog,
  Switch,
  Textarea,
} from "@mui/joy";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Metabolite, MetaboliteScoreSubcategory } from "../../../../../@types";
import { useMetaboliteStore } from "../../../../../store/contexts/metabolite.context";
import { RootState } from "../../../../../store/store";
import api from "../../../../../utils/api/api";
import useDebounce from "../../../../../utils/hooks/useDebounce";

interface EditRelationsMetaboliteDialogProps {
  open: boolean;
  onClose: () => void;
  metaboliteId: number;
}

const EditRelationsMetaboliteDialog = ({
  open,
  onClose,
  metaboliteId,
}: EditRelationsMetaboliteDialogProps) => {
  const [loading, setLoading] = useState(true);
  const authStore = useSelector((state: RootState) => state.auth);
  const { updateOneMetabolite } = useMetaboliteStore();

  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [selectedEditSubcat, setSelectedEditSubcat] =
    useState<MetaboliteScoreSubcategory | null>(null);

  const [metaboliteSubcategoriesScore, setMetaboliteSubcategoriesScore] =
    useState<MetaboliteScoreSubcategory[]>([]);

  const debouncedLoading = useDebounce(loading, 800);

  const handleClose = useCallback(() => {
    onClose();
    setMetaboliteSubcategoriesScore([]);
    setSelectedId(null);
    setSelectedEditSubcat(null);
  }, [onClose]);

  const editMetaboliteSubcategoryId = (
    subcat: MetaboliteScoreSubcategory | null
  ) => {
    setSelectedEditSubcat(subcat);
  };

  const toggleIsActive = () => {
    setSelectedEditSubcat((prev) => {
      if (!prev) return prev;
      return {
        ...prev,
        isActive: !prev.isActive,
      };
    });
  };

  const changeComment = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSelectedEditSubcat((prev) => {
      if (!prev) return prev;
      return {
        ...prev,
        comment: e.target.value,
      };
    });
  };

  const handleSave = async (subcat: MetaboliteScoreSubcategory) => {
    if (!selectedEditSubcat) return;

    if (
      selectedEditSubcat.comment === subcat.comment &&
      selectedEditSubcat.isActive === subcat.isActive
    ) {
      toast.info("No changes to save");
      editMetaboliteSubcategoryId(null);

      return;
    }

    try {
      const responseUpdateSubcategory = await api.post(
        "/backoffice/metabolite/update-score-subcategory",
        {
          id: subcat.id,
          comment: selectedEditSubcat?.comment,
          isActive: selectedEditSubcat?.isActive,
        },
        {
          headers: {
            Authorization: `Bearer ${authStore.token}`,
          },
        }
      );

      const metabolite = responseUpdateSubcategory.data;
      updateOneMetabolite(metabolite as Metabolite);
      editMetaboliteSubcategoryId(null);
      toast.success("Metabolite Score Subcategory updated successfully");
    } catch (err: any) {
      console.log(err);
      if (err.response && err.response.data.message)
        toast.error("Error on update metabolite: " + err.response.data.message);
    }
  };

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const [responseMetabolite] = await Promise.all([
          api.get(`/backoffice/metabolite/${metaboliteId}`, {
            headers: {
              Authorization: `Bearer ${authStore.token}`,
            },
          }),
        ]);

        setMetaboliteSubcategoriesScore(
          responseMetabolite.data
            .metaboliteScoreSubcategoriesComplete as MetaboliteScoreSubcategory[]
        );
      } catch (err: any) {
        console.log(err);
        if (err.response && err.response.data.message)
          toast.error(
            "Error on fetch metabolite data: " + err.response.data.message
          );
        handleClose();
        setMetaboliteSubcategoriesScore([]);
      } finally {
        setLoading(false);
      }
    };

    if (open && metaboliteId > 0) fetchData();
  }, [open, metaboliteId, authStore.token, handleClose]);

  return (
    <Modal open={open} onClose={handleClose}>
      <ModalDialog layout="center">
        <ModalClose />
        <DialogTitle>Edit Metabolite Score Subcategory</DialogTitle>
        {loading || debouncedLoading || !(metaboliteId > 0) ? (
          <div className="w-[500px]">
            <LinearProgress />
          </div>
        ) : (
          <div className="w-[500px] flex flex-col">
            <AccordionGroup
              sx={{
                width: "100%",
                overflowY: "auto",
                height: "500px",
              }}
            >
              {metaboliteSubcategoriesScore.map((subcat) => (
                <Accordion
                  key={subcat.id}
                  expanded={subcat.id === selectedId}
                  onChange={(event, expanded) => {
                    setSelectedId(expanded ? subcat.id : null);
                  }}
                >
                  <AccordionSummary
                    sx={{
                      backgroundColor:
                        subcat.id === selectedId ? "#DDE7EE" : "",
                    }}
                  >
                    <span className={!subcat.isActive ? "line-through" : ""}>
                      {subcat.scoreSubcategory?.name}
                    </span>
                    {subcat.comment.replace(" ", "").length === 0 && (
                      <ReportProblemIcon color="warning" />
                    )}
                    <span className="ml-auto font-normal text-gray-600">
                      ID: {subcat.scoreSubcategory?.id}
                    </span>
                  </AccordionSummary>
                  {selectedEditSubcat?.id === subcat.id ? (
                    <AccordionDetails
                      sx={{
                        backgroundColor:
                          subcat.id === selectedId ? "#F0F4F8" : "",
                        pt: 1,
                        borderRadius: "0 0 8px 8px",
                      }}
                    >
                      <Textarea
                        minRows={4}
                        value={selectedEditSubcat.comment}
                        onChange={changeComment}
                      />
                      <div className="flex items-center mt-4 ">
                        <Switch
                          checked={selectedEditSubcat.isActive}
                          onChange={toggleIsActive}
                        />
                        <div className="ml-auto">
                          <Button
                            variant="plain"
                            onClick={() => editMetaboliteSubcategoryId(null)}
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="solid"
                            onClick={() => handleSave(subcat)}
                          >
                            Save
                          </Button>
                        </div>
                      </div>
                    </AccordionDetails>
                  ) : (
                    <AccordionDetails
                      sx={{
                        backgroundColor:
                          subcat.id === selectedId ? "#F0F4F8" : "",
                        pt: 1,
                        borderRadius: "0 0 8px 8px",
                      }}
                    >
                      <p>
                        <span className="font-semibold">Comment:</span>{" "}
                        {subcat.comment}
                      </p>
                      <div className="mt-4 ml-auto">
                        <Button
                          variant="plain"
                          onClick={() => editMetaboliteSubcategoryId(subcat)}
                        >
                          Edit
                        </Button>
                      </div>
                    </AccordionDetails>
                  )}
                </Accordion>
              ))}
            </AccordionGroup>
          </div>
        )}
      </ModalDialog>
    </Modal>
  );
};

export default EditRelationsMetaboliteDialog;
