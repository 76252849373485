import React, { createContext, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Metabolite, MetaboliteCategory, ScoreSubcategory } from "../../@types";
import api from "../../utils/api/api";
import { RootState } from "../store";

interface MetaboliteContextInterface {
  metabolites: Metabolite[];
  subcategoriesScore: ScoreSubcategory[];
  categories: MetaboliteCategory[];
  updateOneMetabolite: (metabolite: Metabolite) => void;
  fetchMetaboliteData: () => void;
}

export const MetaboliteContext = createContext<MetaboliteContextInterface>(
  {} as MetaboliteContextInterface
);

const MetaboliteProvider = ({ children }: { children: React.ReactNode }) => {
  const authStore = useSelector((state: RootState) => state.auth);

  const [metabolites, setMetabolites] = useState<Metabolite[]>([]);
  const [categories, setCategories] = useState<MetaboliteCategory[]>([]);
  const [subcategoriesScore, setSubcategoriesScore] = useState<
    ScoreSubcategory[]
  >([]);

  const updateOneMetabolite = (metabolite: Metabolite) => {
    const metabolitesUpdated = metabolites.map((metaboliteItem) => {
      if (metaboliteItem.id === metabolite.id) return metabolite;

      return metaboliteItem;
    });

    setMetabolites(metabolitesUpdated);
  };

  const fetchMetaboliteData = async () => {
    try {
      const [
        responseMetabolite,
        responseScoreSubcategories,
        responseCategories,
      ] = await Promise.all([
        api.get("backoffice/metabolite/get-all", {
          headers: {
            Authorization: `Bearer ${authStore.token}`,
          },
        }),
        api.get("backoffice/metabolite/get-all-score-subcategories", {
          headers: {
            Authorization: `Bearer ${authStore.token}`,
          },
        }),
        api.get("/backoffice/metabolite/get-all-categories", {
          headers: {
            Authorization: `Bearer ${authStore.token}`,
          },
        }),
      ]);

      setCategories(responseCategories.data);
      setMetabolites(responseMetabolite.data);
      setSubcategoriesScore(responseScoreSubcategories.data);
    } catch (err: any) {
      console.log(err);
      if (err.response && err.response.data.message)
        toast.error(
          "Error on fetch metabolite context: " + err.response.data.message
        );
    }
  };

  useEffect(() => {
    if (authStore.token && authStore.user?.hasAdminAuth) fetchMetaboliteData();
  }, [authStore.token, authStore.user?.hasAdminAuth]);

  return (
    <MetaboliteContext.Provider
      value={{
        metabolites,
        subcategoriesScore,
        categories,
        updateOneMetabolite,
        fetchMetaboliteData,
      }}
    >
      {children}
    </MetaboliteContext.Provider>
  );
};

const useMetaboliteStore = () => {
  const context = useContext(MetaboliteContext);
  return context;
};

export { MetaboliteProvider, useMetaboliteStore };
