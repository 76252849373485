import {
  DialogTitle,
  Divider,
  Input,
  LinearProgress,
  Modal,
  ModalClose,
  ModalDialog,
  Option,
  Select,
} from "@mui/joy";
import { useState } from "react";
import Collapsible from "react-collapsible";
import { FieldValues, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useRecipientStore } from "../../../../../../store/contexts/recipient.context";
import { useResultsStore } from "../../../../../../store/contexts/results.context";
import { RootState } from "../../../../../../store/store";
import api from "../../../../../../utils/api/api";
import { EnumRecipientStatusID } from "../../../../../../utils/enum/recipient-status.enum";
import InsertResultDialogSearchHeader from "./InsertResultSearchHeader";
import InsertResultTable from "./InsertResultTable";

interface InsertResultDialogProps {
  open: boolean;
  onClose: () => void;
}

const InsertResultDialog = ({ open, onClose }: InsertResultDialogProps) => {
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);

  const [wasSaved, setWasSaved] = useState(false);
  const [loadingPublish, setLoadingPublish] = useState(false);

  const authStore = useSelector((state: RootState) => state.auth);
  const { updateOneRecipient } = useRecipientStore();

  const [recipientId, setRecipientId] = useState<number>();
  const [metaboliteNameSearch, setMetaboliteNameSearch] = useState<string>("");

  const { resultMethods } = useResultsStore();

  const [showResultContent, setShowResultContent] = useState(false);
  const [methodId, setMethodId] = useState<number>(1);

  const handleShowResultContent = () => {
    setShowResultContent(true);
  };

  const handleClose = () => {
    setMethodId(1);
    setRecipientId(undefined);
    setShowResultContent(false);
    onClose();
    setLoadingPublish(false);
    setLoadingSave(false);
    setWasSaved(false);
  };

  const formProps = useForm();

  const { reset, setValue } = formProps;

  const handleChangeMethod = (
    event: React.SyntheticEvent | null,
    newValue: string | null
  ) => {
    setMethodId(Number(newValue));
  };

  const handleChangeMetaboliteNameSearch = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setMetaboliteNameSearch(event.target.value);
  };

  const onSubmitRecipientId = async (data: FieldValues) => {
    setLoading(true);
    try {
      const response = await api.post(
        "/backoffice/results/get-recipient-results-by-id",
        {
          recipientId: Number(data.recipientId),
        },
        {
          headers: {
            Authorization: `Bearer ${authStore.token}`,
          },
        }
      );

      if (
        response.data.status === "default" ||
        response.data.status === "sent_to_client"
      ) {
        throw new Error("Recipient status is not allowed to insert results");
      }

      if (response.data.status === "analysis_completed") {
        throw new Error("Recipient results already published");
      }

      setRecipientId(Number(data.recipientId));

      reset();

      const results = response.data.result as {
        recipientId: number;
        metaboliteId: number;
        resultMethodId: number;
        value: number;
      }[];

      for (const result of results) {
        setValue(
          "metabolite-result-" + result.metaboliteId,
          result.value.toString()
        );
      }
      handleShowResultContent();
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        toast.error(
          `Error fetching recipient results: ${err.response.data.message}`
        );
      } else {
        toast.error(`Error fetching recipient results: ${err.message}`);
      }

      setShowResultContent(false);

      console.log(err);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 800);
    }
  };

  const onSave = async (values: FieldValues) => {
    setLoadingSave(true);
    setWasSaved(false);
    const preparingData = Object.keys(values)
      .map((key) => {
        if (values[key] === "") return undefined;

        return {
          metaboliteId: Number(key.split("-")[2]),
          value: Number(values[key]),
        };
      })
      .filter((elem) => elem !== undefined);

    try {
      await api.post(
        "/backoffice/results/save-result",
        {
          recipientId,
          resultMethodId: methodId,
          results: preparingData,
        },
        {
          headers: {
            Authorization: `Bearer ${authStore.token}`,
          },
        }
      );
      setWasSaved(true);
    } catch (err: any) {
      if (err.response && err.response.data.message)
        toast.error(err.response.data.message);
      console.log(err);
    } finally {
      setLoadingSave(false);
    }
  };

  const handleSave = async (values: FieldValues) => {
    toast.promise(() => onSave(values), {
      pending: "This may take a while",
      success: "Results saved successfully",
      error: "Error saving result",
    });
  };

  const onPublish = async () => {
    setLoadingPublish(true);
    try {
      const response = await api.post(
        `/backoffice/recipient/update/${recipientId}`,
        {
          statusId: EnumRecipientStatusID.ANALYSIS_COMPLETED,
        },
        {
          headers: {
            Authorization: `Bearer ${authStore.token}`,
          },
        }
      );

      updateOneRecipient(response.data);
      toast.success("Recipient updated successfully");
      handleClose();
    } catch (err: any) {
      console.log(err);
      if (err.response && err.response.data.message)
        toast.error("Error on update recipient: " + err.response.data.message);
    } finally {
      setLoadingPublish(false);
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <ModalDialog
        layout="center"
        style={{
          width: "800px",
        }}
      >
        <ModalClose />
        <DialogTitle>Add Results</DialogTitle>
        <InsertResultDialogSearchHeader onSubmit={onSubmitRecipientId} />
        {loading ? (
          <div className="w-full">
            <LinearProgress />
          </div>
        ) : (
          <>
            <Collapsible
              trigger=""
              open={showResultContent}
              transitionTime={200}
              className=""
              contentInnerClassName=" bg-gray-50 p-4 pb-0 rounded-md"
            >
              <Select
                variant="soft"
                placeholder="Select a method"
                sx={{ width: "100%" }}
                defaultValue={1}
                // @ts-ignore
                onChange={handleChangeMethod}
              >
                {resultMethods.map((method) => (
                  <Option key={method.id} value={method.id}>
                    {method.name}
                  </Option>
                ))}
              </Select>
              <Divider
                sx={{
                  my: 2,
                }}
              />
              <Input
                variant="soft"
                placeholder="Metabolite Name"
                sx={{ width: "100%" }}
                value={metaboliteNameSearch}
                onChange={handleChangeMetaboliteNameSearch}
                className="mb-2"
              />
              {
                <InsertResultTable
                  list={
                    resultMethods
                      .find((result) => result.id === methodId)
                      ?.metabolites?.filter((elem) =>
                        elem.name
                          .toLowerCase()
                          .includes(metaboliteNameSearch.toLowerCase())
                      ) || []
                  }
                  formProps={formProps}
                  onSave={handleSave}
                  loadingSave={loadingSave}
                  wasSaved={wasSaved}
                  onPublish={onPublish}
                  loadingPublish={loadingPublish}
                />
              }
            </Collapsible>
          </>
        )}
      </ModalDialog>
    </Modal>
  );
};

export default InsertResultDialog;
