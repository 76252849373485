import { FieldValues, UseFormReturn } from "react-hook-form";
import { Metabolite } from "../../../../../../@types";
import InsertResultMetaboliteRow from "./SeeResultMetaboliteRow";

interface SeeResultTableProps {
  list: Metabolite[];
  formProps: UseFormReturn<FieldValues, any, undefined>;
}

const SeeResultTable = ({ list, formProps }: SeeResultTableProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = formProps;

  return (
    <form className="" onSubmit={handleSubmit(() => null)}>
      <div className="flex flex-col gap-2 overflow-y-auto h-96">
        {list.map((metabolite, index) => (
          <InsertResultMetaboliteRow
            key={metabolite.id}
            register={register}
            htmlId={`metabolite-result-${metabolite.id}`}
            errors={errors}
            metabolite={metabolite}
          />
        ))}
      </div>
    </form>
  );
};

export default SeeResultTable;
