import {
  DialogTitle,
  Divider,
  Input,
  LinearProgress,
  Modal,
  ModalClose,
  ModalDialog,
  Option,
  Select,
} from "@mui/joy";
import { useCallback, useEffect, useState } from "react";
import Collapsible from "react-collapsible";
import { FieldValues, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useResultsStore } from "../../../../../../store/contexts/results.context";
import { RootState } from "../../../../../../store/store";
import api from "../../../../../../utils/api/api";
import InsertResultTable from "./SeeResultTable";

interface SeeResultDialogProps {
  open: boolean;
  onClose: () => void;
  recipientId: number;
}

const SeeResultDialog = ({
  open,
  onClose,
  recipientId,
}: SeeResultDialogProps) => {
  const [loading, setLoading] = useState(false);

  const authStore = useSelector((state: RootState) => state.auth);

  const [metaboliteNameSearch, setMetaboliteNameSearch] = useState<string>("");

  const { resultMethods } = useResultsStore();

  const [showResultContent, setShowResultContent] = useState(false);
  const [methodId, setMethodId] = useState<number>(1);

  const handleShowResultContent = () => {
    setShowResultContent(true);
  };

  const handleClose = () => {
    setMethodId(1);
    setShowResultContent(false);
    onClose();
  };

  const formProps = useForm();

  const { reset, setValue } = formProps;

  const handleChangeMethod = (
    event: React.SyntheticEvent | null,
    newValue: string | null
  ) => {
    setMethodId(Number(newValue));
  };

  const handleChangeMetaboliteNameSearch = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setMetaboliteNameSearch(event.target.value);
  };

  const fetchRecipient = useCallback(
    async (data: FieldValues) => {
      setLoading(true);
      try {
        const response = await api.post(
          "/backoffice/results/get-recipient-results-by-id",
          {
            recipientId: Number(data.recipientId),
          },
          {
            headers: {
              Authorization: `Bearer ${authStore.token}`,
            },
          }
        );

        reset();

        const results = response.data.result as {
          recipientId: number;
          metaboliteId: number;
          resultMethodId: number;
          value: number;
        }[];

        for (const result of results) {
          setValue(
            "metabolite-result-" + result.metaboliteId,
            result.value.toString()
          );
        }
        handleShowResultContent();
      } catch (err: any) {
        if (err.response && err.response.data.message) {
          toast.error(
            `Error fetching recipient results: ${err.response.data.message}`
          );
        } else {
          toast.error(`Error fetching recipient results: ${err.message}`);
        }

        setShowResultContent(false);

        console.log(err);
      } finally {
        setTimeout(() => {
          setLoading(false);
        }, 800);
      }
    },
    [authStore.token, reset, setValue]
  );

  useEffect(() => {
    if (open) {
      fetchRecipient({ recipientId });
    }
  }, [open, fetchRecipient, recipientId]);

  return (
    <Modal open={open} onClose={handleClose}>
      <ModalDialog
        layout="center"
        style={{
          width: "800px",
        }}
      >
        <ModalClose />
        <DialogTitle>See Results</DialogTitle>
        {loading ? (
          <div className="w-full">
            <LinearProgress />
          </div>
        ) : (
          <>
            <Collapsible
              trigger=""
              open={showResultContent}
              transitionTime={200}
              className=""
              contentInnerClassName=" bg-gray-50 p-4 pb-0 rounded-md"
            >
              <Select
                variant="soft"
                placeholder="Select a method"
                sx={{ width: "100%" }}
                defaultValue={1}
                // @ts-ignore
                onChange={handleChangeMethod}
              >
                {resultMethods.map((method) => (
                  <Option key={method.id} value={method.id}>
                    {method.name}
                  </Option>
                ))}
              </Select>
              <Divider
                sx={{
                  my: 2,
                }}
              />
              <Input
                variant="soft"
                placeholder="Metabolite Name"
                sx={{ width: "100%" }}
                value={metaboliteNameSearch}
                onChange={handleChangeMetaboliteNameSearch}
                className="mb-2"
              />
              {
                <InsertResultTable
                  list={
                    resultMethods
                      .find((result) => result.id === methodId)
                      ?.metabolites?.filter((elem) =>
                        elem.name
                          .toLowerCase()
                          .includes(metaboliteNameSearch.toLowerCase())
                      ) || []
                  }
                  formProps={formProps}
                />
              }
            </Collapsible>
          </>
        )}
      </ModalDialog>
    </Modal>
  );
};

export default SeeResultDialog;
