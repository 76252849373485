import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalDialog,
} from "@mui/joy";
import { FieldValues, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as yup from "yup";
import { handlePasswordChange } from "../../store/reducers/authSlice";
import { RootState } from "../../store/store";
import api from "../../utils/api/api";

interface FirstAccessDialogProps {
  open: boolean;
  onClose: () => void;
}

const FirstAccessDialog = ({ onClose, open }: FirstAccessDialogProps) => {
  const authStore = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();

  const formInputNames = {
    password: "password",
  };

  const accessSchema = yup.object().shape({
    [formInputNames.password]: yup
      .string()
      .min(8, "Senha deve ter no mínimo 6 caracteres")
      .required("Campo obrigatório"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(accessSchema),
  });

  const onSubmit = async (values: FieldValues) => {
    try {
      const response = await api.post(
        `/backoffice/user/update-password`,
        {
          password: values.password,
        },
        {
          headers: {
            Authorization: `Bearer ${authStore.token}`,
          },
        }
      );

      dispatch(handlePasswordChange({ user: response.data }));
      toast.success("Senha alterada com sucesso");

      const _setLocalStorage = {
        accessToken: authStore.token,
        user: response.data,
      };

      localStorage.setItem("loginResponse", JSON.stringify(_setLocalStorage));

      onClose();
    } catch (err: any) {
      console.log(err);
      if (err.response && err.response.data.message)
        toast.error("Erro ao alterar senha: " + err.response.data.message);
    }
  };

  return (
    <Modal open={open}>
      <ModalDialog>
        <DialogTitle>Define your own password</DialogTitle>
        <DialogContent>Fill in the input with your new password.</DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl error={Boolean(errors.password)}>
            <FormLabel>Password</FormLabel>
            <Input
              variant="soft"
              type="password"
              placeholder="password"
              {...register(formInputNames.password)}
            />
            <FormHelperText>Min Length: 8 </FormHelperText>
          </FormControl>
          <Button
            type="submit"
            sx={{ mt: 2, ml: "auto", mr: "auto", width: "100%" }}
            size="lg"
          >
            Set new password
          </Button>
        </form>
      </ModalDialog>
    </Modal>
  );
};

export default FirstAccessDialog;
