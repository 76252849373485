import EditIcon from "@mui/icons-material/Edit";
import { Box, IconButton, Sheet, Table, Tooltip } from "@mui/joy";
import { Fragment, useCallback, useMemo, useState } from "react";
import { Freeze, Recipient } from "../../../../../@types";
import { ExpandLess, ExpandMore } from "@mui/icons-material/";
import { ThermometerSnowflake, ThermometerSun } from "lucide-react";
import { useRecipientStore } from "../../../../../store/contexts/recipient.context";
import {
  Timeline,
  TimelineContent,
  TimelineDot,
  TimelineHeading,
  TimelineItem,
  TimelineLine,
} from "../../../../../components/timeline";

interface RecipientsTableProps {
  list: Recipient[];
  onEditRecipient: (id: number) => void;
}

const RecipientsTable = ({ list, onEditRecipient }: RecipientsTableProps) => {
  const { handleFreezeRecipient } = useRecipientStore();

  const [sortColumn, setSortColumn] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
  const [openId, setOpenId] = useState<number | null>(null);

  const onSortChange = useCallback(
    (column: string) => {
      if (sortColumn === column) {
        setSortDirection(sortDirection === "asc" ? "desc" : "asc");
      } else {
        setSortColumn(column);
        setSortDirection("asc");
      }
    },
    [sortColumn, sortDirection]
  );

  const getSpentTime = (freezes: Freeze[]) => {
    if (!freezes?.length) return null;

    let totalUnfreezeTime = 0;
    let lastUnfreezeTimestamp = null;

    for (const freeze of freezes) {
      const currentTimestamp = new Date(freeze.freezeAt).getTime();

      if (freeze.type === "UNFREEZE") lastUnfreezeTimestamp = currentTimestamp;

      if (freeze.type === "FREEZE" && lastUnfreezeTimestamp !== null) {
        totalUnfreezeTime += currentTimestamp - lastUnfreezeTimestamp;
        lastUnfreezeTimestamp = null;
      }
    }

    if (lastUnfreezeTimestamp !== null) {
      const now = new Date().getTime();
      totalUnfreezeTime += now - lastUnfreezeTimestamp;
    }

    const days = Math.floor(totalUnfreezeTime / (1000 * 60 * 60 * 24)) ?? 0;
    const remainingTime = totalUnfreezeTime % (1000 * 60 * 60 * 24);
    const hours = Math.floor(remainingTime / (1000 * 60 * 60)) ?? 0;
    const remainingAfterHours = remainingTime % (1000 * 60 * 60);
    const minutes = Math.floor(remainingAfterHours / (1000 * 60)) ?? 0;

    return {
      days,
      hours,
      minutes,
    };
  };

  const sortedList = useMemo(() => {
    return list.sort((a, b) => {
      if (!sortColumn) return 0;

      if (sortColumn === "timeSpent") {
        const firstSpentTime = getSpentTime(a.freezes);
        const secondSpentTime = getSpentTime(b.freezes);

        const firstSpentTimeInMinutes = firstSpentTime
          ? firstSpentTime.days * 1440 +
            firstSpentTime.hours * 60 +
            firstSpentTime.minutes
          : 0;

        const secondSpentTimeInMinutes = secondSpentTime
          ? secondSpentTime.days * 1440 +
            secondSpentTime.hours * 60 +
            secondSpentTime.minutes
          : 0;

        return sortDirection === "asc"
          ? firstSpentTimeInMinutes - secondSpentTimeInMinutes
          : secondSpentTimeInMinutes - firstSpentTimeInMinutes;
      }

      // @ts-ignore
      if (a[sortColumn] < b[sortColumn])
        return sortDirection === "asc" ? -1 : 1;
      // @ts-ignore
      if (a[sortColumn] > b[sortColumn])
        return sortDirection === "asc" ? 1 : -1;
      return 0;
    });
  }, [list, sortColumn, sortDirection]);

  const memoizedTable = useMemo(() => {
    return (
      <Sheet
        variant="outlined"
        sx={{
          borderRadius: 4,
          "--TableCell-height": "40px",
          "--TableHeader-height": "calc(1 * var(--TableCell-height))",
          "--Table-firstColumnWidth": "80px",
          "--Table-lastColumnWidth": "144px",
          "--TableRow-stripeBackground": "rgba(0 0 0 / 0.04)",
          "--TableRow-hoverBackground": "rgba(0 0 0 / 0.08)",
          overflow: "auto",
          background: (
            theme
          ) => `linear-gradient(to right, ${theme.vars.palette.background.surface} 30%, rgba(255, 255, 255, 0)),
            linear-gradient(to right, rgba(255, 255, 255, 0), ${theme.vars.palette.background.surface} 70%) 0 100%,
            radial-gradient(
              farthest-side at 0 50%,
              rgba(0, 0, 0, 0.12),
              rgba(0, 0, 0, 0)
            ),
            radial-gradient(
                farthest-side at 100% 50%,
                rgba(0, 0, 0, 0.12),
                rgba(0, 0, 0, 0)
              )
              0 100%`,
          backgroundSize:
            "40px calc(100% - var(--TableCell-height)), 40px calc(100% - var(--TableCell-height)), 14px calc(100% - var(--TableCell-height)), 14px calc(100% - var(--TableCell-height))",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "local, local, scroll, scroll",
          backgroundPosition:
            "var(--Table-firstColumnWidth) var(--TableCell-height), calc(100% - var(--Table-lastColumnWidth)) var(--TableCell-height), var(--Table-firstColumnWidth) var(--TableCell-height), calc(100% - var(--Table-lastColumnWidth)) var(--TableCell-height)",
          backgroundColor: "background.surface",
        }}
      >
        <Table
          borderAxis="bothBetween"
          stripe="odd"
          hoverRow
          sx={{
            "& tr > *:first-of-type": {
              position: "sticky",
              left: 0,
              boxShadow: "1px 0 var(--TableCell-borderColor)",
              bgcolor: "background.surface",
            },
            "& tr > *:last-child": {
              position: "sticky",
              right: 0,
              bgcolor: "var(--TableCell-headBackground)",
            },
          }}
        >
          <thead>
            <tr>
              <th style={{ width: 25 }}>
                <button onClick={() => onSortChange("id")}>Id</button>
              </th>
              <th style={{ width: 90 }}>Status</th>
              <th style={{ width: 65 }}>
                <button onClick={() => onSortChange("timeSpent")}>
                  Time Spent
                </button>
              </th>
              <th style={{ width: 65 }}>Seal Code</th>
              <th style={{ width: 200 }}>Customer</th>
              <th style={{ width: 200 }}>Location</th>
              <th style={{ width: 100 }}>Date</th>
              <th aria-label="last" style={{ width: 80 }}>
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {sortedList.map((row) => {
              const spentTime = getSpentTime(row.freezes);

              const firstFreeze = row.freezes?.at(0);
              const lastFreeze = row.freezes?.at(-1);

              const date = firstFreeze?.freezeAt
                ? new Intl.DateTimeFormat(navigator.language, {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                  })
                    .format(new Date(firstFreeze.freezeAt))
                    .replace(",", " ")
                : "-";

              return (
                <Fragment key={row.id}>
                  <tr>
                    <td className="text-xs">
                      {String(row.id).padStart(3, "0")}
                    </td>
                    <td className="w-[90px]">
                      <span className="text-xs text-ellipsis">
                        {row.status.toUpperCase().split("_").join(" ")}
                      </span>
                    </td>
                    <td className="text-xs">
                      {spentTime
                        ? `${spentTime.days} days, ${spentTime.hours} hours, ${spentTime.minutes} minutes`
                        : "-"}
                    </td>
                    <td className="text-sm">{row.sealCode}</td>
                    <td className="text-sm">{`${row.customer?.name ?? ""} - ${
                      row.customer?.email ?? ""
                    }`}</td>
                    <td className="text-sm">
                      {row.location
                        ? `${row.location?.city}, ${row.location?.stateProv} - ${row.location?.countryName}`
                        : `-`}
                    </td>
                    <td className="text-sm">{date}</td>
                    <td>
                      <Box sx={{ display: "flex", gap: 1 }}>
                        <IconButton
                          size="sm"
                          color="neutral"
                          onClick={() =>
                            setOpenId((prevOpenId) =>
                              row.id === prevOpenId ? null : row.id
                            )
                          }
                        >
                          {row.id === openId ? <ExpandLess /> : <ExpandMore />}
                        </IconButton>

                        {lastFreeze?.type &&
                          row.status.toLowerCase() === "sample_validated" && (
                            <Tooltip
                              title={
                                lastFreeze.type === "UNFREEZE"
                                  ? "FREEZE"
                                  : "UNFREEZE"
                              }
                            >
                              <IconButton
                                size="sm"
                                color="neutral"
                                onClick={() => handleFreezeRecipient(row.id)}
                              >
                                {lastFreeze.type === "UNFREEZE" ? (
                                  <ThermometerSnowflake
                                    size={20}
                                    color="#636B74"
                                  />
                                ) : (
                                  <ThermometerSun size={20} color="#636B74" />
                                )}
                              </IconButton>
                            </Tooltip>
                          )}

                        <IconButton
                          size="sm"
                          color="neutral"
                          onClick={() => onEditRecipient(row.id)}
                        >
                          <EditIcon />
                        </IconButton>
                      </Box>
                    </td>
                  </tr>
                  {row.id === openId && (
                    <tr>
                      <td colSpan={6}>
                        <Box>
                          <Timeline positions="left">
                            {row.freezes.map((freeze, index) => {
                              const isLast = index === row.freezes.length - 1;

                              return (
                                <TimelineItem key={freeze.id}>
                                  <TimelineHeading
                                    className="text-sm"
                                    side="right"
                                  >
                                    {new Intl.DateTimeFormat(
                                      navigator.language,
                                      {
                                        day: "2-digit",
                                        month: "2-digit",
                                        year: "numeric",
                                        hour: "2-digit",
                                        minute: "2-digit",
                                      }
                                    )
                                      .format(new Date(freeze.freezeAt))
                                      .replace(",", " ")}
                                  </TimelineHeading>
                                  <TimelineDot
                                    status="custom"
                                    className="p-1 border-primary"
                                    customIcon={
                                      freeze.type === "FREEZE" ? (
                                        <ThermometerSnowflake size={16} />
                                      ) : (
                                        <ThermometerSun size={16} />
                                      )
                                    }
                                  />
                                  {!isLast && <TimelineLine done />}
                                  <TimelineContent className="text-xs">
                                    {freeze.type}
                                  </TimelineContent>
                                </TimelineItem>
                              );
                            })}
                          </Timeline>
                        </Box>
                      </td>
                    </tr>
                  )}
                </Fragment>
              );
            })}
          </tbody>
        </Table>
      </Sheet>
    );
  }, [
    sortedList,
    onSortChange,
    openId,
    handleFreezeRecipient,
    onEditRecipient,
  ]);

  return memoizedTable;
};

export default RecipientsTable;
