import type { ScoreSubcategory, ScoreNutrientByRecipient } from "../../@types";
import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import api from "../../utils/api/api";
import { toast } from "react-toastify";

interface ScoreNutrientsByRecipientContextInterface {
  loadingScores: boolean;
  scores: ScoreNutrientByRecipient[];
  selectedScoreSubCategory: null | number;
  setSelectedScoreSubCategory: (scoreSubCategory: number | null) => void;
  scoreSubCategories: ScoreSubcategory[];
  handleFetchScores: () => Promise<void>;
}

export const ScoreNutrientByRecipientContext =
  createContext<ScoreNutrientsByRecipientContextInterface>(
    {} as ScoreNutrientsByRecipientContextInterface
  );

export const ScoreNutrientByRecipientProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const authStore = useSelector((state: RootState) => state.auth);

  const [loadingScores, setLoadingScores] = useState(true);

  const [selectedScoreSubCategory, setSelectedScoreSubCategory] = useState<
    null | number
  >(null);

  const [scores, setScores] = useState<ScoreNutrientByRecipient[]>([]);
  const [scoreSubCategories, setScoreSubCategories] = useState<
    ScoreSubcategory[]
  >([]);

  const handleFetchScoreSubCategories = useCallback(async () => {
    try {
      const { data } = await api.get(
        "/backoffice/metabolite/get-all-score-subcategories",
        {
          headers: {
            Authorization: `Bearer ${authStore.token}`,
          },
        }
      );

      setScoreSubCategories(data);
    } catch {
      toast.error("Error on fetch score categories");
    }
  }, [authStore.token]);

  const handleFetchScores = useCallback(async () => {
    try {
      setLoadingScores(true);
      const queryParams = selectedScoreSubCategory
        ? `scoreSubCategory=${selectedScoreSubCategory}`
        : "";
      const { data } = await api.get(
        `backoffice/metabolite/scores?${queryParams}`,
        {
          headers: {
            Authorization: `Bearer ${authStore.token}`,
          },
        }
      );

      const mappedScores =
        data.map((recipient: any) => ({
          id: recipient.id,
          reloadScore: recipient.score,
          digestionScore:
            recipient.categories.find(
              (category: any) => category.name === "Digestion"
            )?.score || "-",
          energyScore:
            recipient.categories.find(
              (category: any) => category.name === "Energy"
            )?.score || "-",
          antioxidantScore:
            recipient.categories.find(
              (category: any) => category.name === "Antioxidants"
            )?.score || "-",
          messagingScore:
            recipient.categories.find(
              (category: any) => category.name === "Messaging"
            )?.score || "-",
          detoxScore:
            recipient.categories.find(
              (category: any) => category.name === "Messaging"
            )?.score || "-",
        })) || [];

      setScores(mappedScores);
    } catch (err) {
      setScores([]);
      console.log(err);
    } finally {
      setLoadingScores(false);
    }
  }, [authStore.token, selectedScoreSubCategory]);

  useEffect(() => {
    if (authStore.token) {
      handleFetchScoreSubCategories();
    }
  }, [authStore.token, handleFetchScoreSubCategories]);

  useEffect(() => {
    if (authStore.token) {
      handleFetchScores();
    }
  }, [authStore.token, handleFetchScores]);

  return (
    <ScoreNutrientByRecipientContext.Provider
      value={{
        loadingScores,
        scores,
        scoreSubCategories,
        selectedScoreSubCategory,
        setSelectedScoreSubCategory,
        handleFetchScores,
      }}
    >
      {children}
    </ScoreNutrientByRecipientContext.Provider>
  );
};

export const useScoreNutrientByRecipient = () => {
  return useContext(ScoreNutrientByRecipientContext);
};
