import { Assessment, Refresh, UploadFile } from "@mui/icons-material";
import {
  Box,
  Button,
  LinearProgress,
  Option,
  Select,
  Typography,
} from "@mui/joy";
import { useEffect, useState } from "react";
import { Recipient } from "../../../../@types";
import Header from "../../../../components/layout/Header";
import { useRecipientStore } from "../../../../store/contexts/recipient.context";
import LabEditRecipientDialog from "./components/EditRecipientDialog";
import EditResultDialog from "./components/EditResultDialog/EditResultDialog";
import InsertResultDialog from "./components/InsertResultDialog/InsertResultDialog";
import RecipientsTable from "./components/RecipientsTable";
import SeeResultDialog from "./components/SeeResultDialog/SeeResultDialog";
import UploadResultsDialog from "./components/UploadResultsDialog/UploadResultsDialog";
import { socket } from "../../../../App";
import { CircularProgress } from "@mui/joy";

const ResultsPage = () => {
  const [openInsertResultDialog, setOpenInsertResultDialog] = useState(false);
  const [selectedRecipientIdToEdit, setSelectedRecipientIdToEdit] =
    useState<number>(0);
  const [isOpenEditDialog, setIsOpenEditDialog] = useState(false);
  const [isOpenSeeResultDialog, setIsOpenSeeResultDialog] = useState(false);
  const [isOpenEditResultDialog, setIsOpenEditResultDialog] = useState(false);
  const [isOpenUploadResultsDialog, setIsOpenUploadResultsDialog] =
    useState(false);

    const [uploadingResults, setUploadingResults] = useState(false)

  const [selectedRecipientIdSeeResult, setSelectedRecipientIdSeeResult] =
    useState<number>(0);

  const [selectedRecipientIdEditResult, setSelectedRecipientIdEditResult] =
    useState<number>(0);

  const { recipients, recipientStatus, fetchRecipientData, loadingRecipients } =
    useRecipientStore();
  const [filteredList, setFilteredList] = useState<Recipient[]>([]);

  const [statusSelected, setStatusSelected] = useState("");

  const handleChangeStatus = (
    event: React.SyntheticEvent | null,
    newValue: string | null
  ) => {
    if (!newValue) {
      setStatusSelected("");
      return;
    }
    setStatusSelected(newValue);
  };

  const handleOpenEditResultDialog = (id: number) => {
    setSelectedRecipientIdEditResult(id);
    setIsOpenEditResultDialog(true);
  };

  const handleCloseEditResultDialog = () => {
    setIsOpenEditResultDialog(false);
  };

  const handleOpenInsertResultDialog = () => {
    setOpenInsertResultDialog(true);
  };

  const handleOpenUploadResultsDialog = () => {
    setIsOpenUploadResultsDialog(true);
  };

  const handleCloseInsertResultDialog = () => {
    setOpenInsertResultDialog(false);
  };

  const handleOpenSeeResultDialog = (id: number) => {
    setSelectedRecipientIdSeeResult(id);
    setIsOpenSeeResultDialog(true);
  };

  const handleCloseSeeResultDialog = () => {
    setIsOpenSeeResultDialog(false);
  };

  const handleCloseUploadResultsDialog = () => {
    setIsOpenUploadResultsDialog(false);
  };

  const handleEditRecipient = (id: number) => {
    setSelectedRecipientIdToEdit(id);
    setIsOpenEditDialog(true);
  };

  const handleCloseEditRecipient = () => {
    setSelectedRecipientIdToEdit(0);
    setIsOpenEditDialog(false);
  };

  useEffect(() => {
    let _recipients = recipients;

    if (statusSelected) {
      _recipients = recipients.filter(
        (recipient) => recipient.status === statusSelected
      );
    }
    setFilteredList(_recipients);
  }, [recipients, statusSelected]);

  useEffect(() => {
    socket.on("ProcessedResultsFile", (data) => {
      setUploadingResults(data?.data.status === 'in-progress' ? true : false)

    });
  }, []);

  return (
    <>
      <LabEditRecipientDialog
        onClose={handleCloseEditRecipient}
        open={isOpenEditDialog && selectedRecipientIdToEdit > 0}
        recipientId={selectedRecipientIdToEdit}
      />
      <InsertResultDialog
        open={openInsertResultDialog}
        onClose={handleCloseInsertResultDialog}
      />
      <SeeResultDialog
        open={isOpenSeeResultDialog}
        onClose={handleCloseSeeResultDialog}
        recipientId={selectedRecipientIdSeeResult}
      />
      <EditResultDialog
        open={isOpenEditResultDialog}
        onClose={handleCloseEditResultDialog}
        recipientId={selectedRecipientIdEditResult}
      />
      <UploadResultsDialog
        open={isOpenUploadResultsDialog}
        onClose={handleCloseUploadResultsDialog}
        fetchRecipientData={fetchRecipientData}
      />
      <Header>
        <Typography level="body-lg" fontSize="xl">
          Results
        </Typography>
        <Button
          color="primary"
          startDecorator={<Refresh />}
          variant="outlined"
          sx={{ ml: "auto" }}
          onClick={fetchRecipientData}
        >
          Refresh
        </Button>
      </Header>
      <div className="p-4 rounded">
        <div className="flex gap-2 mb-4">
          <div className="flex w-96 ">
            <Select
              variant="soft"
              placeholder="Select status"
              sx={{ width: "100%" }}
              onChange={handleChangeStatus}
              value={statusSelected}
            >
              <Option value={null}>
                <em>All</em>
              </Option>
              {recipientStatus.map((method) => (
                <Option key={method.id} value={method.status}>
                  {method.status}
                </Option>
              ))}
            </Select>
          </div>
          <Button
            variant="outlined"
            color="success"
            sx={{
              marginLeft: "auto",
            }}
            startDecorator={uploadingResults ?<CircularProgress /> :  <UploadFile />}
            onClick={handleOpenUploadResultsDialog}
            disabled={uploadingResults}
          >
            {uploadingResults ? 'Uploading':  'Upload Results'}
          </Button>
          
          <Button
            variant="solid"
            color="success"
            startDecorator={<Assessment />}
            onClick={handleOpenInsertResultDialog}
          >
            Add Result
          </Button>
        </div>
        <Box sx={{ width: "100%" }}>
          {loadingRecipients ? (
            <div className="">
              <LinearProgress />
            </div>
          ) : (
            <RecipientsTable
              list={filteredList}
              onEditRecipient={handleEditRecipient}
              onSeeResult={handleOpenSeeResultDialog}
              onEditResult={handleOpenEditResultDialog}
            />
          )}
        </Box>
      </div>
    </>
  );
};

export default ResultsPage;
