import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  DialogTitle,
  FormControl,
  FormLabel,
  Grid,
  Input,
  LinearProgress,
  Modal,
  ModalClose,
  ModalDialog,
} from "@mui/joy";
import { useMemo, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useRecipientStore } from "../../../../../store/contexts/recipient.context";
import { RootState } from "../../../../../store/store";
import api from "../../../../../utils/api/api";
import useDebounce from "../../../../../utils/hooks/useDebounce";

interface AddRecipientsDialogProps {
  open: boolean;
  onClose: () => void;
}

const AddRecipientsDialog = ({ open, onClose }: AddRecipientsDialogProps) => {
  const [loading, setLoading] = useState(false);
  const authStore = useSelector((state: RootState) => state.auth);
  //   const { categories, updateOneMetabolite } = useMetaboliteStore();
  const { fetchRecipientData } = useRecipientStore();

  const handleClose = () => {
    onClose();
  };

  const debouncedLoading = useDebounce(loading, 800);

  const formInputNames = useMemo(() => {
    return {
      amount: "amount",
    };
  }, []);

  const recipientSchema = yup.object().shape({
    [formInputNames.amount]: yup.number(),
  });

  const {
    register,
    handleSubmit,
    formState: { isDirty },
  } = useForm({
    resolver: yupResolver(recipientSchema),
  });

  const onSubmit = async (data: FieldValues) => {
    try {
      setLoading(true);
      await api.post(
        "/backoffice/recipient/create-many",
        {
          amount: data.amount,
        },
        {
          headers: {
            Authorization: `Bearer ${authStore.token}`,
          },
        }
      );

      fetchRecipientData();
      toast.success("Recipients created successfully");
      onClose();
    } catch (err: any) {
      console.log(err);
      if (err.response && err.response.data.message)
        toast.error("Error on create recipients: " + err.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <ModalDialog layout="center">
        <ModalClose />
        <DialogTitle>Create Recipient</DialogTitle>
        {loading || debouncedLoading ? (
          <div className="w-[500px]">
            <LinearProgress />
          </div>
        ) : (
          <form className="w-[500px]" onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2} sx={{ flexGrow: 1, width: "100%" }}>
              <Grid xs={12}>
                <FormControl>
                  <FormLabel>
                    How many recipients do you want to create?
                  </FormLabel>
                  <Input
                    variant="soft"
                    {...register(formInputNames.amount)}
                    type="number"
                  />
                </FormControl>
              </Grid>
              <Grid xs={12} sm={12} sx={{ width: "100%", display: "flex" }}>
                <Button
                  disabled={!isDirty}
                  type="submit"
                  variant="soft"
                  sx={{
                    marginLeft: "auto",
                  }}
                >
                  Create
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </ModalDialog>
    </Modal>
  );
};

export default AddRecipientsDialog;
