import { Outlet } from "react-router-dom";
import Sidebar from "../../components/layout/Sidebar";

const RouterDashboardLayout = () => {
  return (
    <div className="relative flex">
      <Sidebar />
      <div className="w-full transition-all ">
        <Outlet />
      </div>
    </div>
  );
};

export default RouterDashboardLayout;
