import axios from "axios";
import isHmlEnv from "../env/isHmlEnv";
import isProdEnv from "../env/isProdEnv";

const baseURL = process.env.REACT_APP_API_URL;
// const baseURL = "http://localhost:8080";
// const baseURL = "https://reload-api-prod-ormxeuosgq-uc.a.run.app";

console.log("API URL: ", baseURL);
console.log("Is Prod Env: ", isProdEnv());
console.log("Is Hml Env: ", isHmlEnv());

const api = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

export default api;
